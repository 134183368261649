import { z } from 'zod'
import { uploadDocument } from 'api/documents/uploadDocument'
import { api } from 'api/api'

export const uploadDevicePhotoSchema = z.object({ documentId: z.string() })

export const uploadDevicePhoto =
  (deviceId: string) => async (fields: FormData) => {
    const document = await uploadDocument(fields)

    return api
      .post('/v1/devices/photos', { deviceId, documentId: document.id })
      .then(() => document)
  }

export const deleteDevicePhoto = (deviceId: string) => () =>
  api.delete(`/v1/devices/${deviceId}/photos`)
