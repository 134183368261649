import { showNotification } from '@mantine/notifications'
import { api } from 'api/api'
import { assignDashboardToDevice } from './assignDashboardToDevice'

export const setDefaultDeviceDashboard = async ({
  deviceId,
  dashboardId,
}: {
  deviceId: string
  dashboardId: string
}) => {
  await assignDashboardToDevice(
    { deviceId },
    { dashboardsId: [dashboardId] },
  ).catch(() =>
    showNotification({
      color: 'red',
      message: 'Failed to assign dashboard to device model',
    }),
  )
  await api
    .put(`/v1/dashboards/devices/${deviceId}/dashboards/${dashboardId}/default`)
    .then(({ data }) => data)
}
