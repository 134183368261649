import { SegmentedControl } from '@mantine/core'
import { dayjsLocaleCodes } from 'hooks/useDayjs'
import { useTranslation } from 'react-i18next'
import { useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { DatePickerInput } from '@mantine/dates'

export const getDateFilterValue = (date: string) => {
  switch (date) {
    case '7d':
      return dayjs().subtract(7, 'day').toISOString()
    case '1m':
      return dayjs().subtract(1, 'month').toISOString()
    case '24h':
      return dayjs().subtract(24, 'hour').toISOString()
    default:
      return date
  }
}

export const ChartFilters = () => {
  const [urlParams, setParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())
  const { i18n } = useTranslation()
  const [value, setValue] = useState<[string | null, string | null]>([
    params?.toDate ? dayjs(params.fromDate).toISOString() : '24h',
    dayjs(params?.toDate).toISOString(),
  ])
  const isInitial = useRef(true)

  const code = i18n.language as keyof typeof dayjsLocaleCodes
  const locale =
    code in dayjsLocaleCodes
      ? dayjsLocaleCodes[code]
      : dayjsLocaleCodes['en-GB']

  useEffect(() => {
    if (!value[0] || !value[1]) return
    if (isInitial.current && value[0] === '24h') return
    setParams(
      {
        fromDate: getDateFilterValue(value[0]),
        toDate: value[1],
      },
      { replace: true },
    )
    isInitial.current = false
  }, [value])

  return (
    <>
      <SegmentedControl
        value={value[0] ?? undefined}
        onChange={date => {
          setValue([date, dayjs().toISOString()])
        }}
        data={[
          { label: '1 day', value: '24h' },
          { label: '7 days', value: '7d' },
          { label: '1 month', value: '1m' },
        ]}
      />
      <DatePickerInput
        type={'range'}
        maxDate={dayjs().toDate()}
        locale={locale}
        valueFormat="DD MMM"
        value={[
          dayjs(getDateFilterValue(value[0] ?? '24h')).toDate(),
          value[1] ? dayjs(value[1]).toDate() : null,
        ]}
        onChange={date => {
          setValue(
            date.map(val =>
              val !== null ? dayjs(val).toISOString() : null,
            ) as [string | null, string | null],
          )
        }}
      />
    </>
  )
}
