import { Card, Group } from '@mantine/core'
import { useFilterForm } from 'hooks/useFilterForm'
import { Fragment } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TbSearch } from 'react-icons/tb'
import { z } from 'zod'
import { AutoSubmit, InputText } from '..'

const schema = z.object({
  search: z.string().optional().nullable(),
})

export const SearchFilters = ({
  noWrapper = false,
}: {
  noWrapper?: boolean
}) => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)
  const Wrapper = noWrapper ? Fragment : Card

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <form onSubmit={onSubmit}>
          <Group>
            <InputText
              name="search"
              icon={TbSearch}
              placeholder={`${t('generic.search')}`}
            />
          </Group>
          <AutoSubmit />
        </form>
      </Wrapper>
    </FormProvider>
  )
}
