import { showNotification } from '@mantine/notifications'
import { api } from 'api/api'
import { assignDashboardToDeviceModel } from './assignDashboardToDeviceModel'

export const setDefaultDeviceModelDashboard = async ({
  deviceModelId,
  dashboardId,
}: {
  deviceModelId: string
  dashboardId: string
}) => {
  await assignDashboardToDeviceModel({ deviceModelId })({
    dashboardsId: [dashboardId],
  }).catch(() =>
    showNotification({
      color: 'red',
      message: 'Failed to assign dashboard to device',
    }),
  )
  await api
    .put(
      `/v1/dashboards/device-models/${deviceModelId}/dashboards/${dashboardId}/default`,
    )
    .then(({ data }) => data)
}
