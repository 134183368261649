import { showNotification } from '@mantine/notifications'
import { logout } from 'api'
import { AxiosError } from 'axios'
import { r } from 'config/routes'
import {
  Navigate,
  RouteObject,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { ErrorView, PanelView, UserButtonProps } from 'components'
import { useAuthActions, useAuthStore } from 'store/authStore'

const PanelLayout = () => {
  const { pathname, search } = useLocation()
  const navigate = useNavigate()
  const { clearLoginData } = useAuthActions()
  const isLoggedIn = useAuthStore(state => state.isLoggedIn)
  const user = useAuthStore(state => state.user)

  const onLogout = async () => {
    try {
      await logout()
      clearLoginData()
      navigate(r['index'])
    } catch (error) {
      if (error instanceof AxiosError) {
        console.dir(error.response)
      }

      showNotification({
        message: 'An error occured while signing out.',
        color: 'red',
      })
      clearLoginData()
      navigate(r['index'])
    }
  }
  if (!isLoggedIn || !user)
    return <Navigate to={r['index']} state={pathname + search} />

  const userData: UserButtonProps = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
  }

  return <PanelView user={userData} onLogout={onLogout} />
}

export const createPanelRoute: (route: RouteObject) => RouteObject = route => ({
  path: route.path,
  element: <PanelLayout />,
  children: [{ ...route, errorElement: <ErrorView /> }],
})
