import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LocationList = lazy(() => import('../List'))
const CreateLocation = lazy(() => import('../Create'))
const SingleLocation = lazy(() => import('../Single'))
const EditLocation = lazy(() => import('../Edit'))

export const locationRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['locations.list'],
    element: <LocationList />,
  }),
  createPanelRoute({
    path: r['locations.create'],
    element: <CreateLocation />,
  }),
  createPanelRoute({
    path: r['locations.view'](),
    element: <SingleLocation />,
  }),
  createPanelRoute({
    path: r['locations.edit'](),
    element: <EditLocation />,
  }),
]
