import { Button, Text, Tooltip } from '@mantine/core'
import {
  TDownlinkMessage,
  TDownlinkMessageList,
} from 'api/loraDevices/getDownlinkMessages'
import Datatable, { TDatatable } from 'components/datatable'
import { TFunction } from 'i18next'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'

const headers: (
  t: TFunction,
) => TDatatable<TDownlinkMessageList['items'][number]>[] = t => [
  {
    id: 'date',
    label: t('generic.date'),
    colSize: 1,
    selector: (item, { dayjs }) => (
      <Text>{dayjs(item.createdAt).format('DD.MM.YYYY, HH:mm:ss')}</Text>
    ),
  },
  {
    id: 'payload',
    label: 'Payload',
    colSize: 2,
    selector: item => (
      <Text sx={{ fontFamily: 'monospace' }} truncate>
        {item.payload}
      </Text>
    ),
  },
  {
    id: 'port',
    label: 'Port',
    colSize: 1,
    selector: item => <Text>{item.port}</Text>,
  },
  {
    id: 'type',
    label: t('generic.type'),
    colSize: 1,
    selector: item => <Text>{item.type}</Text>,
  },
  {
    id: 'status',
    label: 'Status',
    colSize: 1,
    selector: item => (
      <Tooltip label={item.isSent ? 'Sent' : 'Failed'}>
        <Text span color={item.isSent ? 'green' : 'red'}>
          {item.isSent ? (
            <HiCheckCircle size="1.2rem" />
          ) : (
            <HiXCircle size="1.2rem" />
          )}
        </Text>
      </Tooltip>
    ),
  },
  {
    id: 'actions',
    label: '',
    colSize: 1,
    selector: item => <ResendMessage {...item} />,
  },
]

export const DownlinkMessageTable = ({
  data,
}: {
  data: TDownlinkMessageList
}) => {
  const { t } = useTranslation()

  return <Datatable noShadow headers={headers(t)} data={data.items} />
}

export const ResendMessage = memo(({ payload, port }: TDownlinkMessage) => {
  const { setValue } = useFormContext()

  const onClick = () => {
    const presetItem = {
      type: '2',
      fPort: port,
      payload: payload,
    }

    Object.entries(presetItem).forEach(([key, value]) => setValue(key, value))
  }

  return (
    <Button compact onClick={onClick} size="sm">
      Use this preset
    </Button>
  )
})
