import { z } from 'zod'
import { Pagination } from '../helper'
import { DeviceTypeEnum } from './DeviceTypes'
import { localizationSchema } from './Localization'
import { deviceModelListItemSchema } from '../../../../apps/ConnectedLifePanel/src/api/models/getModelList'

export const deviceSchema = z.object({
  deviceId: z.string(),
  type: DeviceTypeEnum,
  provider: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  localization: localizationSchema.omit({ locationId: true }),
  locationId: z.string(),
  modelId: z.string().nullable(),
  model: deviceModelListItemSchema.nullable(),
  photoId: z.string().nullable(),
  photo: z.object({ id: z.string(), url: z.string() }).nullable(),
})

export type TModel = z.infer<typeof deviceModelListItemSchema>

export type TDevice = z.infer<typeof deviceSchema>

export type TDeviceList = Pagination<TDevice>
