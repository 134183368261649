import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import {
  Pagination,
  TChartLabel,
  TChartLabelList,
  TChartLabelListItem,
  TDeviceType,
} from 'shared/types'
import { uniqueArray } from 'utils/uniqueArray'
import { api, getDevicesDetails } from '..'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export type TGetChartLabelListResult = Pagination<
  TChartLabelListItem & {
    deviceName: string
    deviceType: TDeviceType
  }
>
export const getChartLabelList = async (params?: Record<string, string>) => {
  const { items, ...data } = await api
    .get<TChartLabelList>('/v1/charts/labels', { params })
    .then(({ data }) => data)

  const deviceIds = uniqueArray(items.map(item => item.deviceId))

  const details = await getDevicesDetails(deviceIds)

  const newItems = items.map(item => {
    const device = details.get(item.deviceId)!
    return { ...item, deviceName: device?.name, deviceType: device?.type }
  })

  return { ...data, items: newItems }
}

export const getChartLabelListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getChartLabelList(params),
  queryKey: ['chartLabels', params],
})

export const useGetChartLabelList = (manualParams?: Record<string, string>) =>
  usePaginatedQuery(getChartLabelListQuery, manualParams)

export type TChartLabelsParams = {
  fromDate: string
  toDate: string
  deviceId: string
  indicatorCode: string
}
export const getChartLabels = (params: Record<string, string>) =>
  api
    .get<TChartLabel[]>('/v1/charts/labels/filters', { params })
    .then(({ data }) => data)

export const getChartLabelsQuery = (params: Record<string, string>) => ({
  queryKey: ['chartLabelsFilters', params],
  queryFn: () => getChartLabels(params),
  // staleTime: 5000,
})

export const useGetChartLabels = ({
  deviceId,
  indicatorCode,
}: Pick<TChartLabelsParams, 'deviceId' | 'indicatorCode'>) => {
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())

  const query = getChartLabelsQuery({ deviceId, indicatorCode, ...params })

  return useQuery(query)
}
