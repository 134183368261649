import { Card, Group } from '@mantine/core'
import { getIndicatorsOptionsHelper } from 'api/devices'
import { InputDeviceSelect } from 'components/devices/DeviceSelectInput'
import { AutoSubmit, InputAsyncSelect, InputDate } from 'components/form'
import dayjs from 'dayjs'
import { useFilterForm } from 'hooks/useFilterForm'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'shared/utils'
import { z } from 'zod'

const schema = z.object({
  indicatorCode: z.string().nullish(),
  deviceId: z.string().nullish(),
  dateFrom: z.string().datetime().nullish(),
  dateTo: z.string().datetime().nullish(),
})

export const MeasurementsFilters = ({
  noWrapper = false,
  excludeDevice,
}: {
  noWrapper?: boolean
  excludeDevice?: boolean
}) => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)
  const Wrapper = noWrapper ? 'div' : Card

  return (
    <FormProvider {...methods}>
      <Wrapper style={{ overflow: 'visible' }}>
        <form onSubmit={onSubmit}>
          <Group>
            <InputAsyncSelect
              {...getIndicatorsOptionsHelper(t)}
              name="indicatorCode"
              placeholder={capitalizeFirstLetter(t('sensor.indicator'))}
              clearable
            />
            {!excludeDevice && <InputDeviceSelect />}
            <InputDate
              placeholder={capitalizeFirstLetter(t('generic.from'))}
              name="dateFrom"
              maxDate={dayjs().toDate()}
              clearable
            />
            <InputDate
              placeholder={capitalizeFirstLetter(t('generic.to'))}
              name="dateTo"
              maxDate={dayjs().toDate()}
              clearable
            />
          </Group>
          <AutoSubmit />
        </form>
      </Wrapper>
    </FormProvider>
  )
}
