import { hideNotification, showNotification } from '@mantine/notifications'
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from '@microsoft/signalr'
import { useQueryClient } from '@tanstack/react-query'
import { API_URL } from 'config/env'
import {
  ComponentType,
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Loader } from './Loader'

type TSignalRContext = HubConnection | undefined

const SignalRContext = createContext<TSignalRContext>(
  new HubConnectionBuilder()
    .withUrl(`${API_URL}/measurements`)
    .withAutomaticReconnect()
    .build(),
)
export const useConnection = () => {
  const context = useContext(SignalRContext)

  if (context === undefined) throw new Error('Missing SignalR Provider')

  return context
}

const useInitConnection = ({ onConnect }: { onConnect?: () => void }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { deviceId } = useParams()

  const { current: connection } = useRef<HubConnection>(
    new HubConnectionBuilder()
      .withUrl(`${API_URL}/measurements?deviceId=${deviceId}`)
      .withAutomaticReconnect()
      .build(),
  )

  const initConnection = useCallback(async () => {
    try {
      await connection.start()

      connection.onreconnecting(() => {
        showNotification({
          loading: true,
          message: t('generic.reconnecting'),
          withCloseButton: false,
          id: 'reconnecting',
          autoClose: Infinity,
        })
      })

      connection.onreconnected(() => {
        hideNotification('reconnecting')
      })

      onConnect && onConnect()
    } catch (e) {
      // showNotification({
      //   color: 'red',
      //   message: 'An error occured while connecting to WebSocket server.',
      // })
      onConnect && onConnect()
      console.log({ e })
    }
  }, [queryClient])

  useEffect(() => {
    if (!deviceId) throw new Error('Missing deviceId')

    if (connection.state === HubConnectionState.Disconnected) initConnection()

    return () => {
      if (connection.state === HubConnectionState.Connected) connection.stop()
    }
  }, [deviceId])

  return connection
}

const SignalRProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true)
  const connection = useInitConnection({ onConnect: () => setLoading(false) })

  return (
    <SignalRContext.Provider value={connection}>
      {loading ? <Loader /> : children}
    </SignalRContext.Provider>
  )
}

const withSignalR =
  <C extends object>(Component: ComponentType<C>) =>
  (props: C) =>
    (
      <SignalRProvider>
        <Component {...props} />
      </SignalRProvider>
    )

export default withSignalR
