import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DraggableAttributes,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { Group, Stack } from '@mantine/core'
import { DashboardRow } from './DashboardRow'
import { useReorderColumns } from './hooks/useReorderColumns'
import { restrictToParentElement } from '@dnd-kit/modifiers'
import { useReorderRows } from './hooks/useReorderRows'
import { createContext, useContext, useMemo } from 'react'
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { TDashboard } from 'api/dashboards/getDashboard'
import { AddRowButton } from './AddRowButton'
import { DashboardSelector } from './DashboardSelector'
import { ChartFilters } from 'components/chart/filters/ChartFilters'
import { DashboardCustomizer } from './DashboardCustomizer'
import { NoDataPrompt } from '..'
import { useTranslation } from 'react-i18next'
import {
  useDashboardActions,
  useDashboardStore,
} from './hooks/useDashboardStore'
import { BiCustomize } from 'react-icons/bi'

const MEASURE_ALWAYS = 0

export const DashboardProvider = ({
  data,
  onChange,
}: {
  data: TDashboard
  onChange: () => void
}) => {
  const { t } = useTranslation()
  const isEditMode = useDashboardStore(state => state.isEditMode)
  const { toggleEditMode } = useDashboardActions()
  const itemIds = useMemo(() => data.rows.map(row => row.id), [data.rows])

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const reorderColumns = useReorderColumns()
  const reorderRows = useReorderRows({ dashboardId: data.id })

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || !over.id) return
    if (active.id === over.id) return
    if (!active.data.current) return

    switch (active.data.current.type) {
      case 'COL':
        reorderColumns({ active, over })
        break
      case 'ROW':
        reorderRows({ active, over })
        break
    }
  }

  return (
    <DashboardContext.Provider value={data}>
      <Stack>
        <Group position="apart">
          <DashboardSelector onChange={onChange} />
          <Group>
            <ChartFilters />
            <DashboardCustomizer onAdd={onChange} />
          </Group>
        </Group>
        <DndContext
          measuring={{
            droppable: {
              strategy: MEASURE_ALWAYS,
            },
          }}
          modifiers={[restrictToParentElement]}
          onDragEnd={handleDragEnd}
          collisionDetection={closestCorners}
          sensors={sensors}
        >
          <SortableContext
            strategy={verticalListSortingStrategy}
            items={itemIds}
          >
            {data.rows.map(row => (
              <DashboardRow key={row.id} {...row} />
            ))}
            {data.rows.length === 0 && !isEditMode && (
              <NoDataPrompt
                title={t('dashboard.createCTA')}
                buttonContent={t('dashboard.edit')}
                onClick={() => toggleEditMode()}
                icon={BiCustomize}
              />
            )}
            <AddRowButton />
          </SortableContext>
        </DndContext>
      </Stack>
    </DashboardContext.Provider>
  )
}

export type SortableItemProps = {
  listeners?: SyntheticListenerMap
  attributes?: DraggableAttributes
  style?: Record<string, string | number | undefined>
}

const DashboardContext = createContext<TDashboard | null>(null)
export const useDashboardContext = () => {
  const dashboard = useContext(DashboardContext)
  if (dashboard === null) throw new Error('Missing dashboard info')

  return dashboard
}
