import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { addRow, TAddRowResponse } from 'api/dashboards/addRow'
import { TDashboard } from 'api/dashboards/getDashboard'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { ErrorResponse } from 'shared/types'
import { useDashboardContext } from '../DashboardProvider'

export const useInsertRow = () => {
  const deviceId = useParams().deviceId as string
  const queryClient = useQueryClient()
  const dashboard = useDashboardContext()
  const queryKey = ['dashboards', 'device', deviceId, 'default']

  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while adding new row',
    })
  }

  const onSuccess = async (data: TAddRowResponse) => {
    await queryClient.cancelQueries()
    queryClient.setQueryData<TDashboard>(queryKey, old => {
      if (!old) return

      return {
        ...old,
        rows: [
          ...old.rows,
          {
            id: data.rowId,
            columns: [],
            orderNo: dashboard.rows.length + 1,
            dashboardId: dashboard.id,
            type: 'ROW',
          },
        ],
      }
    })
  }

  const mutation = useMutation({ mutationFn: addRow, onSuccess, onError })

  return mutation
}
