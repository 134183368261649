import { useQuery } from '@tanstack/react-query'
import { TMeasurementItem } from 'shared/types'
import { api } from '..'

export const getLastMeasurement = (deviceId: string, indicatorCode: string) =>
  api
    .get<TMeasurementItem>('/v1/measurements/last-value', {
      params: {
        deviceId,
        indicatorCode,
      },
    })
    .then(({ data }) => data)

export const lastMeasurementQueryKey = ({
  deviceId,
  indicatorCode,
}: {
  deviceId: string
  indicatorCode: string
}) => ['measurements.chart.lastValue', deviceId, indicatorCode]

export const lastMeasurementQuery = (
  deviceId: string,
  indicatorCode: string,
) => ({
  queryFn: () => getLastMeasurement(deviceId, indicatorCode),
  queryKey: lastMeasurementQueryKey({ deviceId, indicatorCode }),
})

export const useGetLastMeasurement = (
  deviceId?: string,
  indicatorCode?: string,
) => {
  if (!deviceId || !indicatorCode)
    throw new Error('Missing deviceId or indicatorCode')

  const query = lastMeasurementQuery(deviceId, indicatorCode)

  return useQuery({
    ...query,
    useErrorBoundary: false,
    staleTime: 5 * 60 * 1000,
  })
}
