import { api } from 'api/api'
import { dashboardSchema } from '../getDashboard'

export const getDefaultDeviceModelDashboard = async (deviceModelId: string) => {
  const data = await api
    .get(`/v1/dashboards/deviceModels/${deviceModelId}/default`)
    .then(({ data }) => dashboardSchema.parse(data))

  // TODO: PLS END ME BEFORE I END THE BACKEND TEAM
  if (data.id === '00000000-0000-0000-0000-000000000000')
    throw new Error('No default dashboard specified')

  return data
}
