import { Button, Center, Grid } from '@mantine/core'
import { getDeviceIndicatorsOptionsHelper } from 'api/devices'
import { useTranslation } from 'react-i18next'
import { TbPlus } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { useDashboardStore } from './hooks/useDashboardStore'
import { InputAsyncSelect, IconButton, AutoSubmit } from '..'
import { FormProvider } from 'react-hook-form'
import { capitalizeFirstLetter } from 'shared/utils'
import { useMemo, useState } from 'react'
import { IoClose } from 'react-icons/io5'
import { useInsertColumn } from './hooks/useInsertColumn'
import { calculateRemainingSpace } from './utils/calculateRemainingSpace'
import { useDashboardContext } from './DashboardProvider'
import { dashboardColumnSize } from './utils/dashboardColumnSize'
import { getModelIndicatorsOptionsHelper } from 'api/models/getModelIndicators'
import {
  useDeviceModelContext,
  useDeviceTypeContext,
} from 'components/devices/DeviceIndicators'
import { getDeviceOidsIndicatorsHelper } from 'api/snmpOids/getSnmpDeviceOids'

export const AddColumnButton = ({ rowId }: { rowId: string }) => {
  const dashboard = useDashboardContext()
  const isEditMode = useDashboardStore(state => state.isEditMode)
  const [isFormView, setFormView] = useState(false)
  const { t } = useTranslation()
  const SMALLEST_COLUMN = dashboardColumnSize.get('OneOnThree')!.colSpan
  const hasAvailableSpace = useMemo(
    () =>
      calculateRemainingSpace(
        dashboard.rows.find(row => row.id === rowId)!.columns,
      ) >= SMALLEST_COLUMN,
    [dashboard],
  )

  const exitInsertMode = () => setFormView(false)

  if (!isEditMode || !hasAvailableSpace) return null

  return (
    <Grid.Col span={2}>
      {isFormView ? (
        <Center
          sx={theme => {
            const colors = theme.fn.variant({ variant: 'default' })
            return {
              minHeight: 350,
              border: `1px dashed ${colors.border}`,
              backgroundColor: colors.background,
              color: colors.color,
              borderRadius: theme.radius.md,
              position: 'relative',
            }
          }}
        >
          <>
            <AddColumnForm onSuccess={exitInsertMode} rowId={rowId} />
            <ExitInsertMode onExit={exitInsertMode} />
          </>
        </Center>
      ) : (
        <Button
          sx={{
            minHeight: 350,
            borderStyle: 'dashed',
          }}
          fullWidth
          size="xl"
          variant="default"
          leftIcon={<TbPlus />}
          onClick={() => setFormView(true)}
        >
          {t('dashboard.insertColumn')}
        </Button>
      )}
    </Grid.Col>
  )
}

const AddColumnForm = ({
  rowId,
  onSuccess,
}: {
  rowId: string
  onSuccess: () => void
}) => {
  const { t } = useTranslation()
  const { deviceId } = useParams()

  if (!deviceId) throw new Error('Missing deviceId')

  const { methods, onSubmit } = useInsertColumn({
    rowId,
    handleSuccess: onSuccess,
  })

  const indicatorHelper = useGetIndicatorsList({ deviceId })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <InputAsyncSelect
          name="indicator"
          label={capitalizeFirstLetter(t('sensor.indicator'))}
          {...indicatorHelper}
        />
        <AutoSubmit />
      </form>
    </FormProvider>
  )
}

const ExitInsertMode = ({ onExit }: { onExit: () => void }) => {
  const { t } = useTranslation()
  return (
    <IconButton
      sx={{ position: 'absolute', top: '1em', right: '1em' }}
      onClick={onExit}
      icon={IoClose}
      label={t('generic.cancel')}
    />
  )
}

const useGetIndicatorsList = ({ deviceId }: { deviceId: string }) => {
  const { t } = useTranslation()
  const deviceModel = useDeviceModelContext()
  const deviceType = useDeviceTypeContext()

  const loraHelper = deviceModel
    ? getModelIndicatorsOptionsHelper(deviceModel, t)
    : getDeviceIndicatorsOptionsHelper(deviceId!, t)

  const snmpHelper = getDeviceOidsIndicatorsHelper(deviceId)

  return useMemo(
    () => (deviceType === 'SNMP' ? snmpHelper : loraHelper),
    [deviceType, deviceId],
  )
}
