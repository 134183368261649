import { useEffect, useState } from 'react'
import {
  Box,
  Collapse,
  createStyles,
  getStylesRef,
  Group,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb'
import { Link, NavLink, useMatch } from 'react-router-dom'
import { IconType } from 'react-icons'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'

export interface LinkCollapse {
  label: string
  link: string
}

const useStyles = createStyles(
  (theme, { isCollapsed }: { isCollapsed: boolean }) => {
    const control = getStylesRef('control')
    return {
      control: {
        ref: control,
        fontWeight: 500,
        width: isCollapsed ? 58 : 'auto',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        background: 'transparent',
        borderRadius: '4px',
        transition: 'width .4s cubic-bezier(.25,.8,.25,1);',
      },

      link: {
        transition: 'all .4s cubic-bezier(.25,.8,.25,1);',
        [`.${control}:hover &`]: {
          transform: 'translateX(10px)',
        },
      },

      navLink: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        width: 150,
      },

      sublink: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        paddingLeft: 30,
        marginLeft: 30,
        fontSize: theme.fontSizes.sm,
        color: theme.white,
        borderLeft: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.dark[4]
            : theme.colors.gray[3]
        }`,

        '&:hover, &.is-active': {
          color: theme.black,
          background: theme.fn.gradient({
            from: theme.colors.brand[4],
            to: theme.colors.brand[7],
            deg: 45,
          }),
        },
      },

      chevron: {
        transition: 'transform 200ms ease',
      },
    }
  },
)

interface LinksGroupProps {
  isCollapsed: boolean
  icon: IconType
  initiallyOpened?: boolean
  label: string
  href?: string
  links?: LinkCollapse[]
}

export function LinksGroup({
  icon: Icon,
  label,
  initiallyOpened = false,
  href,
  links,
  isCollapsed,
}: LinksGroupProps) {
  const { classes, theme, cx } = useStyles({
    isCollapsed,
  })

  const mobileMenuOpened = useCustomizerStore(state => state.mobileMenuOpened)
  const { toggleMobileMenu } = useCustomizerActions()

  const hasLinks = Array.isArray(links)
  const [opened, setOpened] = useState(initiallyOpened || false)
  const ChevronIcon = theme.dir === 'ltr' ? TbChevronRight : TbChevronLeft
  const items = (hasLinks ? links : []).map(link => {
    const isActive = useMatch({
      path: link.link,
      end: true,
    })

    useEffect(() => {
      if (isActive) setOpened(true)
    }, [])

    return (
      <NavLink
        className={({ isActive }) =>
          cx(classes.sublink, 'link-label', isActive && 'is-active')
        }
        to={link.link}
        key={link.label}
        onClick={() => {
          if (mobileMenuOpened) toggleMobileMenu()
        }}
        data-testid={`navigate-${link.link.substring(1)}`}
      >
        {link.label}
      </NavLink>
    )
  })

  return (
    <>
      <UnstyledButton
        onClick={() => setOpened(o => !o)}
        className={cx(classes.control, 'nav-link')}
        data-testid={`navigate-${label.toLowerCase()}`}
      >
        <Group className={classes.link} position="apart" spacing={0}>
          {href && (
            <Box
              component={Link}
              to={href}
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {Icon && <Icon size={28} />}
              <Text ml="md" className={cx(classes.navLink, 'link-label')}>
                {label}
              </Text>
            </Box>
          )}
          {hasLinks && (
            <Group align={'center'} position="apart" noWrap>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {Icon && <Icon size={28} />}
                <Text ml="md" className={cx(classes.navLink, 'link-label')}>
                  {label}
                </Text>
              </Box>
              <ChevronIcon
                className={classes.chevron}
                size={14}
                style={{
                  transform: opened
                    ? `rotate(${theme.dir === 'rtl' ? -90 : 90}deg)`
                    : 'none',
                }}
              />
            </Group>
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? (
        <Collapse className="nav-collapse" in={opened}>
          {items}
        </Collapse>
      ) : null}
    </>
  )
}
