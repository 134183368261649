import { GenericAbortSignal } from 'axios'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { registerToken } from 'api/notifications/registerToken'
import { firebaseConfig, FIREBASE_VAPID_KEY } from './firebaseConfig'
import { showNotification } from '@mantine/notifications'
import { MdOutlineNotificationImportant } from 'react-icons/md'
import { z } from 'zod'
import localforage from 'localforage'
import { initializeApp } from 'firebase/app'

const notificationSchema = z.object({
  from: z.string(),
  messageId: z.string(),
  data: z.object({ title: z.string(), body: z.string(), date: z.string() }),
})

const FCM_TOKEN = 'fcm_token'
export const removeFcmToken = () => localforage.removeItem(FCM_TOKEN)
export const fcmTokenInStorage = () => localforage.getItem(FCM_TOKEN)

export const setupFirebase = async (signal?: GenericAbortSignal) => {
  if (!('serviceWorker' in navigator))
    throw new Error('Service workers unsupported')

  try {
    const app = initializeApp(firebaseConfig, 'ConnectedLife')
    const messaging = getMessaging(app)

    if (!(await fcmTokenInStorage())) {
      const scriptUrl = new URL('./firebase-messaging-sw.js', import.meta.url)

      const registration = await navigator?.serviceWorker
        ?.getRegistration(scriptUrl.href)
        .catch(() => undefined)

      const token = await getToken(messaging, {
        vapidKey: FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: import.meta.env.DEV
          ? registration ??
            (await navigator.serviceWorker.register(scriptUrl, {
              type: 'module',
              scope: scriptUrl.href,
            }))
          : undefined,
      })
      localforage.setItem(FCM_TOKEN, token)
      await registerToken(token, signal)
    }

    onMessage(messaging, payload => {
      const { data } = notificationSchema.parse(payload)
      showNotification({
        color: 'red',
        icon: <MdOutlineNotificationImportant size="1.2rem" />,
        title: data.title,
        message: data.body,
      })
    })
  } catch (error) {
    console.dir(error)
    showNotification({
      color: 'red',
      title: 'Error occured while initializing push notifications',
      message: error instanceof Error ? error.message : 'Unknown error',
    })
  }
}
