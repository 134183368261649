import {
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from '@dnd-kit/sortable'
import { Flex, Grid, Stack, ThemeIcon } from '@mantine/core'
import { DashboardColumn } from './DashboardColumn'
import { useDashboardStore } from './hooks/useDashboardStore'
import { CSS } from '@dnd-kit/utilities'
import { RxDragHandleDots2 } from 'react-icons/rx'
import { forwardRef, Ref, useMemo } from 'react'
import { UniqueIdentifier } from '@dnd-kit/core'
import { SortableItemProps } from './DashboardProvider'
import { TDashboardRow } from 'api/dashboards/getDashboard'
import { AddColumnButton } from './AddColumnButton'
import { DeleteDashboardRow } from './DeleteDashboardRow'

export const DashboardRow = (row: TDashboardRow) => {
  const isEditMode = useDashboardStore(state => state.isEditMode)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: row.id,
    data: row,
    disabled: !isEditMode,
    animateLayoutChanges: () => false,
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : undefined,
  }

  return (
    <DashboardRowItem
      ref={setNodeRef}
      style={style}
      listeners={listeners}
      attributes={attributes}
      {...row}
    />
  )
}

export const DashboardRowItem = forwardRef(
  (
    {
      style,
      attributes,
      listeners,
      columns,
      id,
    }: TDashboardRow & SortableItemProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const isEditMode = useDashboardStore(state => state.isEditMode)
    const items = useMemo(
      () => columns.map(column => column.id as UniqueIdentifier),
      [columns],
    )

    return (
      <Flex gap={'lg'} mih={150} align={'center'} ref={ref} style={style}>
        {isEditMode && (
          <Stack>
            <ThemeIcon
              variant="light"
              sx={{ cursor: 'pointer' }}
              {...attributes}
              {...listeners}
            >
              <RxDragHandleDots2 />
            </ThemeIcon>
            <DeleteDashboardRow rowId={id} />
          </Stack>
        )}
        <Grid align={'center'} gutter={'lg'} sx={{ flexGrow: 1 }} columns={6}>
          <SortableContext
            strategy={horizontalListSortingStrategy}
            key={id}
            items={items}
          >
            {columns.map(column => (
              <DashboardColumn {...column} key={column.id} />
            ))}
          </SortableContext>
          <AddColumnButton rowId={id} />
        </Grid>
      </Flex>
    )
  },
)
DashboardRowItem.displayName = 'DashboardRowItem'
