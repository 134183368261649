import { MantineSize, TextInput } from '@mantine/core'
import {
  Controller,
  FieldError,
  RefCallBack,
  useFormContext,
} from 'react-hook-form'
import { IconType } from 'react-icons'

export type InputTextProps = {
  name: string
  label?: string | React.ReactNode
  icon?: IconType
  description?: string
  placeholder?: string
  required?: boolean
  disabled?: boolean
  type?: string
  className?: string
  size?: MantineSize
}

export interface InputTextPureProps {
  label?: string | React.ReactNode
  icon?: IconType
  description?: string
  placeholder?: string
  disabled?: boolean
  error?: FieldError
  innerRef?: RefCallBack
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  value?: string
  type?: string
  className?: string
  size?: MantineSize
}

export const InputText = ({
  name,
  icon,
  className,
  ...props
}: InputTextProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <InputTextPure
            {...field}
            {...props}
            innerRef={ref}
            className={className}
            error={error}
            icon={icon}
          />
        )
      }}
    />
  )
}

export const InputTextPure = ({
  icon: Icon,
  error,
  innerRef,
  onChange,
  value,
  className,
  ...props
}: InputTextPureProps) => {
  return (
    <TextInput
      ref={innerRef}
      className={className}
      icon={Icon ? <Icon size={'1.2em'} /> : null}
      error={error?.message}
      onChange={onChange}
      value={value ?? ''}
      {...props}
    />
  )
}
