import { icon } from 'leaflet'
import { Marker, Popup } from 'react-leaflet'
import { Anchor } from '@mantine/core'
import { Link } from 'react-router-dom'
import { getDeviceRoute } from 'views/panel/DeviceGroups/Single/getDeviceRoute'
import { TDevice, TDeviceType } from 'shared/types'
import DeviceIcon from 'assets/markers/Device.svg'
import GatewayIcon from 'assets/markers/Gateway.svg'

export const DeviceMarkerIcon = icon({
  iconUrl: DeviceIcon,
  iconRetinaUrl: DeviceIcon,
  iconSize: [30, 40],
  iconAnchor: [15, 40],
  popupAnchor: [-3, -40],
})

export const GatewayMarkerIcon = icon({
  iconUrl: GatewayIcon,
  iconRetinaUrl: GatewayIcon,
  iconSize: [30, 40],
  iconAnchor: [15, 40],
  popupAnchor: [-3, -40],
})

export const getMarkerIcon = (type: TDeviceType) => {
  switch (type) {
    case 'LoraGateway':
      return GatewayMarkerIcon
    default:
      return DeviceMarkerIcon
  }
}

export const DeviceMarker = ({
  type,
  name,
  deviceId,
  localization: { latitude, longitude },
  noPopup = false,
}: Pick<TDevice, 'type' | 'localization'> & {
  noPopup?: boolean
  deviceId?: string
  name?: string
}) => {
  if (!latitude || !longitude) return null

  const icon = getMarkerIcon(type)

  return (
    <Marker icon={icon} position={[latitude, longitude]}>
      {(!noPopup || !deviceId || !name) && (
        <Popup>
          <Anchor
            component={Link}
            to={getDeviceRoute(type)(deviceId, 'indicators')}
          >
            {name}
          </Anchor>
        </Popup>
      )}
    </Marker>
  )
}
