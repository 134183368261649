import { z } from 'zod'
import { api } from '../..'

const schema = z.object({
  dashboardsId: z.array(z.string()),
})
type TAssignDashboardToModelFields = z.infer<typeof schema>

export const assignDashboardToDeviceModel =
  ({ deviceModelId }: { deviceModelId: string }) =>
  (data: TAssignDashboardToModelFields) =>
    api.post(`/v1/dashboards/device-models/${deviceModelId}/dashboards`, data)
