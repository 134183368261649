import { DatePickerInput, DatePickerInputProps } from '@mantine/dates'
import dayjs from 'dayjs'
import 'dayjs/locale/pl'
import { dayjsLocaleCodes } from 'hooks/useDayjs'
import { forwardRef, Ref } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { TbCalendar } from 'react-icons/tb'

export const InputDate = ({
  name,
  icon: Icon,
  required,
  ...props
}: Omit<DatePickerInputProps, 'onChange' | 'value' | 'name'> & {
  name: string
  icon?: IconType
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        return (
          <InputDatePure
            {...field}
            {...props}
            error={error?.message}
            icon={
              Icon ? <Icon size={'1.2em'} /> : <TbCalendar size={'1.2em'} />
            }
            withAsterisk={required}
          />
        )
      }}
    />
  )
}

const InputDatePure = forwardRef(
  (
    props: Omit<DatePickerInputProps, 'onChange' | 'value'> & {
      onChange: (_date: string | undefined) => void
      value: string
    },
    ref: Ref<HTMLButtonElement>,
  ) => {
    const { i18n } = useTranslation()

    const code = i18n.language as keyof typeof dayjsLocaleCodes
    const locale =
      code in dayjsLocaleCodes
        ? dayjsLocaleCodes[code]
        : dayjsLocaleCodes['en-GB']

    const onChange = (value: Date | null) => {
      const valueAsISOString = value ? dayjs(value).toISOString() : undefined
      props.onChange(valueAsISOString)
    }

    const value = props.value ? dayjs(props.value).toDate() : undefined

    return (
      <DatePickerInput
        ref={ref}
        {...props}
        locale={locale}
        onChange={onChange}
        value={value}
        miw={150}
        clearable={!props.required}
      />
    )
  },
)

InputDatePure.displayName = 'InputDatePure'
