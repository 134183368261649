import { Providers } from 'config/Providers'
import Router from 'views/Router'
import 'config/i18n'
import 'leaflet/dist/leaflet.css'

function App() {
  return (
    <Providers>
      <Router />
    </Providers>
  )
}

export default App
