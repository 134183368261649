import { z } from 'zod'
import { AlertLevelEnum } from '../alertRules'
import { Pagination } from '../helper'
import { alertStatusSchema, exceedSchema } from './TAlert'

export const alertListItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  level: AlertLevelEnum,
  status: alertStatusSchema,
  lastUpdatedAt: z.string().nullable(),
  exceeds: z.array(exceedSchema),
})

export type TAlertListItem = z.infer<typeof alertListItemSchema>

export type TAlertList = Pagination<TAlertListItem>
