import { Card, Group } from '@mantine/core'
import { useFilterForm } from 'hooks/useFilterForm'
import { Fragment } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TbSearch } from 'react-icons/tb'
import { z } from 'zod'
import {
  AutoSubmit,
  InputDate,
  InputSelect,
  InputText,
  SelectItemWithIcon,
} from '..'
import { alertLevelOptions } from 'api/alertRules'
import { alertStatusOptions } from 'data/alertStatus'
import { InputDeviceSelect } from 'components/devices/DeviceSelectInput'
import { capitalizeFirstLetter } from 'shared/utils'
import { useDayjs } from 'hooks/useDayjs'

const schema = z.object({
  search: z.string().nullish(),
  fromDate: z.string().nullish(),
  toDate: z.string().nullish(),
  status: z.string().nullish(),
  level: z.string().nullish(),
})

export const AlertsFilters = ({
  noWrapper = false,
  excludeDevice,
}: {
  excludeDevice?: boolean
  noWrapper?: boolean
}) => {
  const { t } = useTranslation()
  const dayjs = useDayjs()
  const { methods, onSubmit } = useFilterForm(schema)
  const Wrapper = noWrapper ? Fragment : Card

  return (
    <FormProvider {...methods}>
      <Wrapper>
        <form onSubmit={onSubmit}>
          <Group>
            <InputText
              name="search"
              icon={TbSearch}
              placeholder={`${t('generic.search')}`}
            />
            <InputSelect
              name="level"
              data={alertLevelOptions(t)}
              placeholder={t('alert.level')!}
              itemComponent={SelectItemWithIcon}
              clearable
            />
            <InputSelect
              name="status"
              data={alertStatusOptions(t)}
              placeholder={t('generic.status')!}
              itemComponent={SelectItemWithIcon}
              clearable
            />
            {excludeDevice ? null : <InputDeviceSelect />}
            <InputDate
              placeholder={capitalizeFirstLetter(t('generic.from'))}
              name="fromDate"
              maxDate={dayjs().toDate()}
              clearable
            />
            <InputDate
              placeholder={capitalizeFirstLetter(t('generic.to'))}
              name="toDate"
              maxDate={dayjs().toDate()}
              clearable
            />
          </Group>
          <AutoSubmit />
        </form>
      </Wrapper>
    </FormProvider>
  )
}
