import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import {
  DeepPartial,
  FieldValues,
  Path,
  SubmitHandler,
  useForm,
} from 'react-hook-form'
import { ErrorResponse } from 'shared/types'
import { nullsToUndefined } from 'shared/utils'
import { ZodType } from 'zod'
import { useMemo } from 'react'

type FormMutationOptions<Fields, Response> = {
  schema: ZodType
  mutationFn: (data: Fields) => Promise<Response>
  onSuccess?: (data: Response, variables: Fields, context: unknown) => void
  onError?: (
    error: AxiosError<ErrorResponse>,
    variables: Fields,
    context?: unknown,
  ) => void
  initial?: Partial<Fields>
}

export const useFormMutation = <Fields extends FieldValues, Response>({
  schema,
  mutationFn,
  onSuccess,
  onError,
  initial,
}: FormMutationOptions<Fields, Response>) => {
  const methods = useForm<Fields>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(
      () =>
        ({
          ...(initial &&
            nullsToUndefined(initial as Record<string, undefined>)),
        } as DeepPartial<Fields>),
      [],
    ),
  })

  const onInnerError = (
    error: AxiosError<ErrorResponse>,
    variables: Fields,
  ) => {
    if (error.response?.status === 401) {
      methods.setError('status' as never, {
        type: 'API',
        message: 'Brak uprawnień (401)',
      })
    } else if (error.response?.status === 403) {
      methods.setError('status' as never, {
        type: 'API',
        message: 'Brak uprawnień (403)',
      })
    } else if (error.response?.status === 404) {
      methods.setError('status' as never, {
        type: 'API',
        message: 'Nie znaleziono zasobu (404)',
      })
    } else if (error.response?.status === 405) {
      methods.setError('status' as never, {
        type: 'API',
        message: 'Nieprawidłowa metoda (405)',
      })
    } else if (error.response?.status === 500) {
      methods.setError('status' as never, {
        type: 'API',
        message: 'Wystąpił nieoczekiwany błąd. Spróbuj ponownie później (500)',
      })
    }

    if (error.response?.data && Array.isArray(error.response.data.errors)) {
      const errors = error.response.data.errors

      errors.forEach(error => {
        const fieldName = (
          error?.name
            ? error.name.slice(0, 1).toLowerCase() + error.name.slice(1)
            : 'server'
        ) as Path<Fields>

        methods.setError(fieldName, error)
      })
    }

    onError && onError(error, variables)
  }

  const mutation = useMutation({
    mutationFn,
    onSuccess,
    onError: onInnerError,
  })

  const submitPromise: SubmitHandler<Fields> = async data => {
    try {
      return await mutation.mutateAsync(data)
    } catch {
      return false
    }
  }

  const onSubmit = methods.handleSubmit(submitPromise)

  return { methods, onSubmit, isLoading: mutation.isLoading }
}
