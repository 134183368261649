import { api } from '..'
import { z } from 'zod'
import { paginationSchema } from '../../utils/paginationSchema'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export const deviceModelListItemSchema = z.object({
  id: z.string(),
  provider: z.string().nullable(),
  name: z.string(),
  organizationId: z.string().nullable(),
  photoId: z.string().nullable(),
  photo: z.object({ id: z.string(), url: z.string() }).nullable(),
})
export const deviceModelListSchema = paginationSchema(deviceModelListItemSchema)

export type TModel = z.infer<typeof deviceModelListItemSchema>

export type TModelList = z.infer<typeof deviceModelListSchema>

export const getModelsList = (params?: Record<string, string>) =>
  api
    .get('/v1/device-models', { params })
    .then(({ data }) => deviceModelListSchema.parse(data))

export const modelsListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getModelsList(params),
  queryKey: ['device-models', params],
})

export const useGetModelsList = () => usePaginatedQuery(modelsListQuery)

export const getDeviceModelsSelect = () =>
  api.get<{ items: TModel[] }>('/v1/device-models/list').then(({ data }) =>
    data.items.map(({ id, name, provider }) => ({
      value: id,
      label: name,
      group: provider ?? 'Other',
    })),
  )

export const deviceModelsSelectQuery = {
  queryKey: ['device-models', 'select'],
  queryFn: getDeviceModelsSelect,
}
