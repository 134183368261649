/* eslint-disable @typescript-eslint/no-explicit-any */
import { UseQueryResult } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useMemo } from 'react'
import { TChartLabel, TMeasurementItem } from 'shared/types'
import { ApexOptions } from 'apexcharts'
import { useMantineColorScheme, useMantineTheme } from '@mantine/core'
import pl from 'apexcharts/dist/locales/pl.json'
import en from 'apexcharts/dist/locales/en.json'
import { useTranslation } from 'react-i18next'

// Can't type ApexCharts :D
type TUseAreaChartOptions = {
  disableToolbar?: boolean
  measurements: UseQueryResult<TMeasurementItem[]>
  labels?: UseQueryResult<TChartLabel[]>
  title: string
  deviceId: string
  synchronize?: boolean
  events?: {
    animationEnd?(chart: any, options?: any): void
    beforeMount?(chart: any, options?: any): void
    mounted?(chart: any, options?: any): void
    updated?(chart: any, options?: any): void
    mouseMove?(e: any, chart?: any, options?: any): void
    mouseLeave?(e: any, chart?: any, options?: any): void
    click?(e: any, chart?: any, options?: any): void
    xAxisLabelClick?(e: any, chart?: any, options?: any): void
    legendClick?(chart: any, seriesIndex?: number, options?: any): void
    markerClick?(e: any, chart?: any, options?: any): void
    selection?(chart: any, options?: any): void
    dataPointSelection?(e: any, chart?: any, options?: any): void
    dataPointMouseEnter?(e: any, chart?: any, options?: any): void
    dataPointMouseLeave?(e: any, chart?: any, options?: any): void
    beforeZoom?(chart: any, options?: any): void
    beforeResetZoom?(chart: any, options?: any): void
    zoomed?(chart: any, options?: any): void
    scrolled?(chart: any, options?: any): void
    brushScrolled?(chart: any, options?: any): void
  }
}

export const useAreaChartOptions = ({
  measurements: { data, isSuccess },
  labels,
  title,
  events,
  deviceId,
  disableToolbar,
  synchronize,
}: TUseAreaChartOptions) => {
  const { t } = useTranslation()
  const chartTitle = t(`indicator.${title}`)
  const unit = Array.isArray(data) && data.length > 0 ? data[0].unit : null
  const { i18n } = useTranslation()
  const code = i18n.language
  const { colorScheme } = useMantineColorScheme()
  const theme = useMantineTheme()

  const series = useMemo(
    () =>
      data && isSuccess
        ? [
            {
              name: chartTitle,
              data: data?.map(item => ({
                x: dayjs(item.date).format(),
                y: item.value,
              })),
            },
          ]
        : [],
    [data, isSuccess],
  )

  const annotations = useMemo(
    () =>
      labels && labels.isSuccess
        ? [
            ...labels.data.map(label => ({
              x: dayjs(label.dateFrom).valueOf(),
              x2: dayjs(label.dateTo).valueOf(),
              fillColor: label?.hexColor ?? '#ef7171',
              label: {
                text: label?.title,
                textAnchor: 'start',
                style: {
                  background:
                    colorScheme === 'light' ? '#fff' : theme.colors.dark[6],
                },
              },
            })),
          ]
        : [],
    [labels?.data, labels?.isSuccess, colorScheme],
  )

  const options: ApexOptions = useMemo(
    () => ({
      annotations: {
        xaxis: annotations,
      },
      chart: {
        id: `${deviceId}-${title}`,
        group: synchronize ? deviceId : undefined,
        locales: [pl, en],
        defaultLocale: code.substring(0, 2),
        foreColor: colorScheme === 'dark' ? theme.white : theme.black,
        height: 350,
        zoom: {
          enabled: true,
          autoScaleYaxis: true,
        },
        fontFamily: 'Poppins, sans-serif',
        selection: {
          enabled: true,
          type: 'x',
          fill: {
            color: colorScheme === 'light' ? '#24292e' : theme.colors.gray[3],
          },
        },
        forecastDataPoints: {
          count: 50,
          fillOpacity: 0.5,
          strokeWidth: undefined,
          dashArray: 4,
        },

        toolbar: {
          show: !disableToolbar,
          autoSelected: 'zoom',
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: false,
            reset: false,
          },
        },
        ...(events && { events }),
      },
      noData: {
        text: t('generic.noData')!,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      xaxis: {
        type: 'datetime',
        labels: {
          datetimeUTC: false,
        },
      },
      yaxis: {
        title: {
          text: unit?.symbol,
        },
        labels: {
          minWidth: 40,
          formatter: val => (val !== null ? val.toFixed(2) : val),
        },
      },
      tooltip: {
        x: {
          show: true,
          format: 'dd.MM HH:mm',
        },
        y: {
          formatter: function (value) {
            return `${value !== null ? value.toFixed(2) : ''} ${
              unit ? unit?.symbol : ''
            }`
          },
        },
        theme: colorScheme,
      },
      colors: [
        colorScheme === 'dark' ? theme.colors.brand[5] : theme.colors.brand[6],
      ],
    }),
    [colorScheme, unit, annotations, disableToolbar],
  )

  return { options, series }
}
