import { z } from 'zod'

export const parserTypeSchema = z.enum(['BYTES', 'BITS', 'JSON', 'SNMP'])

export const parserPacketItemSchema = z.object({
  id: z.string().optional(),
  indicator: z.string().optional().nullable(),
  path: z.string().optional().nullable(),
  rangeFrom: z.number(),
  rangeTo: z.number(),
  multiplier: z.number(),
})

export const parserSchema = z.object({
  id: z.string(),
  name: z.string(),
  type: parserTypeSchema.nullable(),
  organizationId: z.string().nullable(),
  items: z.array(parserPacketItemSchema),
  models: z.array(z.string()),
  devices: z.array(z.string()),
})

export type TParserType = z.infer<typeof parserTypeSchema>

export type TParser = z.infer<typeof parserSchema>

export type TParserPacketItem = z.infer<typeof parserPacketItemSchema>
