import React from 'react'
import { Group, Pagination as MantinePagination, Select } from '@mantine/core'
import { useSearchParams } from 'react-router-dom'

type CPaginationProps = {
  totalPages: number
  currentPage: number
  resultsPerPage: number
  totalResults: number
  disablePerPage?: boolean
}

const perPageOptions = [10, 25, 50, 100].map(option => option.toString())

export const ListPagination = ({
  totalPages,
  currentPage,
  resultsPerPage,
  totalResults,
  disablePerPage,
}: CPaginationProps) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const params = Object.fromEntries(searchParams)

  const handlePageChange = (page: number) => {
    setSearchParams({ ...params, page: page.toString() })
  }

  const handlePerPageChange = (value: string | null) => {
    if (!value) return
    setSearchParams({ ...params, results: value })
  }

  return (
    <Group data-testid="pagination" position="apart">
      {totalPages > 1 && (
        <MantinePagination
          total={totalPages}
          value={currentPage}
          withEdges
          onChange={handlePageChange}
        />
      )}
      {!disablePerPage && totalResults > 10 && (
        <Select
          ml="auto"
          value={resultsPerPage.toString()}
          data={perPageOptions}
          onChange={handlePerPageChange}
        />
      )}
    </Group>
  )
}
