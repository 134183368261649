import { Menu } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { copyDashboard } from 'api/dashboards/dashboardCopy'
import { useDeviceModelContext } from 'components/devices/DeviceIndicators'
import { useTranslation } from 'react-i18next'
import { TbCopy } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { CreateDashboardForm } from './DashboardCreate'

export const DashboardCopy = ({
  onSuccess,
  dashboardId,
}: {
  onSuccess: () => void
  dashboardId: string
}) => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const modelId = useDeviceModelContext()

  const onClick = () => {
    openModal({
      title: t('dashboard.copy'),
      children: (
        <CreateDashboardForm
          mutationFn={copyDashboard({ deviceId: deviceId!, dashboardId })}
          deviceId={deviceId!}
          onSuccess={onSuccess}
          modelId={modelId}
          submitLabel={t('generic.copy')!}
        />
      ),
    })
  }

  return (
    <Menu.Item onClick={onClick} icon={<TbCopy />}>
      {t('dashboard.copy')}
    </Menu.Item>
  )
}
