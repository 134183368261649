import { useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { api } from '..'
import { TDeviceList } from 'shared/types'
import getDeviceIcon from 'config/deviceIcons'
import { useTranslation } from 'react-i18next'
import { getDeviceRoute } from 'views/panel/DeviceGroups/Single/getDeviceRoute'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export const getDeviceList = (params?: Record<string, string>) =>
  api
    .get<TDeviceList>('/v1/devices', {
      params,
    })
    .then(({ data }) => data)

export const deviceListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getDeviceList(params),
  queryKey: ['devices.all', params],
})

export const deviceListHelper = (params: Record<string, string>) => ({
  queryFn: (searchParams: Record<string, string>) =>
    getDeviceList({ ...searchParams, ...params }).then(data =>
      data.items.map(device => ({
        value: device.deviceId,
        label: device.name ?? device.provider,
        description: device.type,
        icon: getDeviceIcon(device.type),
      })),
    ),
  queryKey: ['devices.select', params],
})

export const useGetDevicesList = (manualFilters?: Record<string, string>) =>
  usePaginatedQuery(deviceListQuery, manualFilters)

export const useGetDevicesSpotlight = (search: string | undefined) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return useQuery({
    queryFn: () =>
      search
        ? getDeviceList({ search: search! }).then(({ items }) =>
            items.map(item => {
              const Icon = getDeviceIcon(item.type)
              return {
                title: item.name,
                description: t(`typeCode.${item.type}`),
                icon: <Icon size="1.2rem" />,
                onTrigger: () =>
                  navigate(
                    getDeviceRoute(item.type)(
                      item.deviceId,
                      item.type === 'SNMP' ? 'measurements' : 'indicators',
                    ),
                  ),
              }
            }),
          )
        : [],
    queryKey: ['devices.spotlight', { search }],
    enabled: search !== undefined,
  })
}
