import { paginationSchema } from 'utils/paginationSchema'
import { z } from 'zod'
import { api } from '..'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export const loraSessionsSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  appNonce: z.string(),
  devAddr: z.string(),
  appSKey: z.string(),
  nwkSKey: z.string(),
  createdAt: z.string(),
})

export type TLoraDeviceSessions = Awaited<
  ReturnType<typeof getLoraDeviceSessions>
>
const getLoraDeviceSessions = (
  deviceId: string,
  params?: Record<string, string>,
) =>
  api
    .get(`/v1/lorawan/devices/${deviceId}/sessions`, { params })
    .then(({ data }) => paginationSchema(loraSessionsSchema).parse(data))

const loraDeviceSessionsQuery =
  (deviceId: string) => (params?: Record<string, string>) => ({
    queryKey: ['loradevice', deviceId, 'sessions', params],
    queryFn: () => getLoraDeviceSessions(deviceId, params),
  })

export const useGetLoraDeviceSessions = (deviceId: string) =>
  usePaginatedQuery(loraDeviceSessionsQuery(deviceId))
