import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const ParserList = lazy(() => import('../List'))
const ParserEdit = lazy(() => import('../Edit'))
const ParserCreate = lazy(() => import('../Create'))
const ParserSingle = lazy(() => import('../Single'))
const ParserTester = lazy(() => import('../Tester'))

export const parsersRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['parser.list'],
    element: <ParserList />,
  }),
  createPanelRoute({
    path: r['parser.add'],
    element: <ParserCreate />,
  }),
  createPanelRoute({
    path: r['parser.view'](),
    element: <ParserSingle />,
  }),
  createPanelRoute({
    path: r['parser.edit'](),
    element: <ParserEdit />,
  }),
  createPanelRoute({
    path: r['parser.tester'],
    element: <ParserTester />,
  }),
]
