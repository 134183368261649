import { Checkbox, CheckboxProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

interface InputCheckboxProps extends CheckboxProps {
  name: string
}

export const InputCheckbox = ({ name, ...props }: InputCheckboxProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <Checkbox
            {...field}
            {...props}
            checked={value}
            ref={ref}
            error={error?.message}
          />
        )
      }}
    />
  )
}
