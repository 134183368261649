import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { api } from '..'
import { createDashboard } from './dashboardCreate'
import { getDefaultDeviceDashboard } from './deviceDashboards/getDeviceDefaultDashboard'
import { getDefaultDeviceModelDashboard } from './deviceModelDashboards/getDeviceModelDefaultDashboard'

export const dashboardColumnSchema = z.object({
  id: z.string(),
  orderNo: z.number(),
  columnSize: z.string(),
  dashboardRowId: z.string(),
  indicator: z.string(),
})

export const dashboardRowSchema = z.object({
  id: z.string(),
  orderNo: z.number(),
  dashboardId: z.string(),
  columns: z.array(dashboardColumnSchema).catch([]),
})

export const dashboardSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullish(),
  isDefault: z.boolean(),
  rows: z.array(dashboardRowSchema).catch([]),
})
type TDashboardSchema = z.infer<typeof dashboardSchema>

export type TDashboard = Awaited<ReturnType<typeof getSingleDashboard>>
export type TDashboardRow = TDashboard['rows'][number]
export type TDashboardColumn = TDashboardRow['columns'][number]
export type TDashboardItemType = 'ROW' | 'COL'
export type TDashboardType = 'MODEL' | 'DEVICE'

export const mapDashboard = (
  dashboard: TDashboardSchema,
  type: TDashboardType,
) => ({
  ...dashboard,
  type,
  rows: dashboard.rows
    .sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1))
    .map(row => ({
      ...row,
      type: 'ROW' as TDashboardItemType,
      columns: row.columns
        .sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1))
        .map(col => ({
          ...col,
          type: 'COL' as TDashboardItemType,
        })),
    })),
})

export const getSingleDashboard = async (
  dashboardId: string,
  type: TDashboardType,
) => {
  const dashboard = await api
    .get(`/v1/dashboards/${dashboardId}`)
    .then(({ data }) => dashboardSchema.parse(data))

  return mapDashboard(dashboard, type)
}

export const ensureDashboard = async ({
  deviceId,
  deviceModelId,
}: {
  deviceId: string
  deviceModelId?: string
}) => {
  try {
    const deviceDashboard = await getDefaultDeviceDashboard(deviceId)

    return mapDashboard(deviceDashboard, 'DEVICE')
  } catch (error) {
    try {
      if (!deviceModelId) throw new Error('Missing deviceModelId')
      const modelDashboard = await getDefaultDeviceModelDashboard(deviceModelId)

      return mapDashboard(modelDashboard, 'MODEL')
    } catch (error) {
      const newDashboard = await createDashboard({ deviceId: deviceId })({})

      return getSingleDashboard(newDashboard.dashboardId, 'DEVICE')
    }
  }
}

export const useGetDeviceDefaultDashboard = ({
  deviceId,
  deviceModelId,
}: {
  deviceId: string
  deviceModelId?: string
}) => {
  return useQuery({
    queryKey: ['dashboards', 'device', deviceId, 'default'],
    queryFn: () => ensureDashboard({ deviceId, deviceModelId }),
    retry: false,
    useErrorBoundary: false,
  })
}
