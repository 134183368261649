import { Pagination } from '../helper'
import { z } from 'zod'

export const loraDeviceListItemSchema = z.object({
  deviceId: z.string(),
  eui: z.string(),
  appEUI: z.string(),
  appKey: z.string().nullish(),
})

export type TLoraDeviceListItem = z.infer<typeof loraDeviceListItemSchema>

export type TLoraDeviceList = Pagination<TLoraDeviceListItem>
