import { openConfirmModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { removeDeviceDashboard } from 'api/dashboards/deviceDashboards/removeDeviceDashboard'
import { removeDeviceModelDashboard } from 'api/dashboards/deviceModelDashboards/removeDeviceDashboard'
import { TAvailableDashboardsList } from 'api/dashboards/getAvailableDashboards'
import { TDashboard } from 'api/dashboards/getDashboard'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

export const useRemoveDashboard = (
  { id, type }: Pick<TDashboard, 'id' | 'type'>,
  deviceModelId: string | null,
) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const deviceId = useParams().deviceId as string
  const queryKey = [
    'dashboards',
    'available',
    { deviceId, modelId: deviceModelId },
  ]

  const mutationFn =
    type === 'DEVICE'
      ? removeDeviceDashboard({ dashboardId: id, deviceId })
      : deviceModelId
      ? removeDeviceModelDashboard({ dashboardId: id, deviceModelId })
      : removeDeviceDashboard({ dashboardId: id, deviceId })

  const onMutate = async () => {
    showNotification({
      color: 'green',
      message: 'Please select new dashboard',
    })
    await queryClient.cancelQueries({ queryKey })
    const previousData =
      queryClient.getQueryData<TAvailableDashboardsList>(queryKey)

    queryClient.setQueryData<TAvailableDashboardsList>(queryKey, old => {
      if (!old) return

      return old.map(dashboard =>
        dashboard.id === id ? { ...dashboard, disabled: true } : dashboard,
      )
    })

    return { previousData }
  }

  const onError = (
    _error: unknown,
    _variables: void,
    context?: { previousData?: TAvailableDashboardsList },
  ) => {
    if (context?.previousData) {
      queryClient.setQueryData<TAvailableDashboardsList>(
        queryKey,
        context.previousData,
      )
    }
  }

  const mutation = useMutation({
    mutationFn,
    onMutate,
    onError,
  })

  const openModal = () => {
    openConfirmModal({
      title: t('dashboard.delete'),
      children:
        type === 'DEVICE'
          ? t('dashboard.deleteDeviceConfirm')
          : t('dashboard.deleteModelConfirm'),
      confirmProps: { color: 'red', disabled: mutation.isLoading },
      onConfirm: () => mutation.mutate(),
      labels: { confirm: t('generic.delete'), cancel: t('generic.cancel') },
    })
  }

  return openModal
}
