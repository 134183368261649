import { ActionIcon, Button, createStyles, Group, Menu } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useMutation } from '@tanstack/react-query'
import { setDefaultDeviceModelDashboard } from 'api/dashboards/deviceModelDashboards/setAsDefaultDeviceModelDashboard'
import { TDashboard } from 'api/dashboards/getDashboard'
import { AxiosError } from 'axios'
import { useDeviceModelContext } from 'components/devices/DeviceIndicators'
import { useTranslation } from 'react-i18next'
import { BiCustomize } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { TbCheckbox, TbChevronDown, TbEdit, TbTrash } from 'react-icons/tb'
import { ErrorResponse } from 'shared/types'
import { DashboardCopy } from './DashboardCopy'
import { DashboardCreate } from './DashboardCreate'
import { useDashboardContext } from './DashboardProvider'
import { EditDashboardInfoForm } from './EditDashboardInfo'
import {
  useDashboardActions,
  useDashboardStore,
} from './hooks/useDashboardStore'
import { useRemoveDashboard } from './hooks/useRemoveDashboard'

export const DashboardCustomizer = ({ onAdd }: { onAdd: () => void }) => {
  const dashboard = useDashboardContext()
  const { toggleEditMode } = useDashboardActions()
  const isEditMode = useDashboardStore(state => state.isEditMode)

  const onToggle = () => {
    toggleEditMode()
  }

  const { classes, theme } = useStyles()

  const Icon = isEditMode ? IoClose : BiCustomize

  return (
    <Group noWrap spacing={0}>
      <Button onClick={onToggle} className={classes.button}>
        <Icon size="1.2rem" />
      </Button>
      <Menu
        transitionProps={{
          transition: 'pop',
        }}
        position="bottom-end"
        withinPortal
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
          >
            <TbChevronDown size="1rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <DashboardCreate onSuccess={onAdd} />
          <DashboardCopy dashboardId={dashboard.id} onSuccess={onAdd} />
          <EditDashboard />
          <SetAsDefault />
          <DeleteDashboard {...dashboard} />
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}

const useStyles = createStyles(theme => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `2px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white
    }`,
  },
}))

const DeleteDashboard = ({ id, type }: TDashboard) => {
  const { t } = useTranslation()
  const deviceModelId = useDeviceModelContext()
  const openModal = useRemoveDashboard({ id, type }, deviceModelId)

  return (
    <Menu.Item onClick={openModal} c={'red'} icon={<TbTrash />}>
      {t('generic.delete')}
    </Menu.Item>
  )
}

const SetAsDefault = () => {
  const { t } = useTranslation()
  const dashboard = useDashboardContext()
  const deviceModelId = useDeviceModelContext()
  const mutation = useMutation({
    mutationFn: setDefaultDeviceModelDashboard,
    onSuccess: () => {
      showNotification({
        color: 'green',
        message: t('dashboard.defaultSetSuccess'),
      })
    },
    onError: (data: AxiosError<ErrorResponse>) => {
      showNotification({
        color: 'red',
        message:
          data.response?.data.errors.map(error => error.message).join(', ') ??
          t('generic.errorOccured'),
      })
    },
  })

  if (!deviceModelId) return null

  return (
    <Menu.Item
      onClick={() =>
        mutation.mutate({ deviceModelId, dashboardId: dashboard.id })
      }
      icon={<TbCheckbox />}
    >
      {t('dashboard.setAsDefaultForModel')}
    </Menu.Item>
  )
}

const EditDashboard = () => {
  const dashboard = useDashboardContext()
  const { t } = useTranslation()
  const onClick = () => {
    openModal({
      title: t('dashboard.edit'),
      children: (
        <EditDashboardInfoForm
          id={dashboard.id}
          initial={{ name: dashboard.name }}
        />
      ),
    })
  }

  return (
    <Menu.Item onClick={onClick} icon={<TbEdit />}>
      {t('generic.edit')}
    </Menu.Item>
  )
}
