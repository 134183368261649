import { Group, ScrollArea, SegmentedControl } from '@mantine/core'
import { useMutation } from '@tanstack/react-query'
import { setDefaultDeviceDashboard } from 'api/dashboards/deviceDashboards/setAsDefaultDeviceDashboard'
import {
  TAvailableDashboardList,
  useGetAvailableDashboards,
} from 'api/dashboards/getAvailableDashboards'
import { useDeviceModelContext } from 'components/devices/DeviceIndicators'
import { useParams } from 'react-router-dom'
import { Query } from '..'
import { useDashboardContext } from './DashboardProvider'

export const DashboardSelector = ({ onChange }: { onChange: () => void }) => {
  const dashboard = useDashboardContext()
  const { deviceId } = useParams()
  const deviceModelId = useDeviceModelContext()
  const query = useGetAvailableDashboards({
    deviceId: deviceId!,
    modelId: deviceModelId,
  })

  const mutation = useMutation({
    mutationFn: setDefaultDeviceDashboard,
    onSuccess: onChange,
  })

  return (
    <Group align={'center'}>
      <Query.Wrapper disableNoDataComponent query={query}>
        <Query.Data>
          {(data: TAvailableDashboardList) => (
            <ScrollArea
              sx={theme => ({
                width: `min(350px, calc(100vw - ${theme.spacing.lg} - ${theme.spacing.lg} - ${theme.spacing.md} - ${theme.spacing.md}))`,
              })}
              h={50}
              type="auto"
            >
              <SegmentedControl
                disabled={mutation.isLoading}
                data={data.map(item => ({
                  value: item.id,
                  label: item.name ?? 'No name',
                  disabled: item.disabled,
                }))}
                value={dashboard.id}
                onChange={val =>
                  mutation.mutate({
                    deviceId: deviceId!,
                    dashboardId: val,
                  })
                }
              />
            </ScrollArea>
          )}
        </Query.Data>
      </Query.Wrapper>
    </Group>
  )
}
