import { api } from 'api/api'

export const removeDeviceModelDashboard =
  ({
    dashboardId,
    deviceModelId,
  }: {
    dashboardId: string
    deviceModelId: string
  }) =>
  () =>
    api.delete(
      `/v1/dashboards/device-models/dashboards/${dashboardId}/device-models/${deviceModelId}`,
    )
