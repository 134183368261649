import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TDashboard } from 'api/dashboards/getDashboard'
import {
  setColumnSize,
  TSetColumnSizeFields,
} from 'api/dashboards/setColumnSize'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { ErrorResponse } from 'shared/types'

export const useResizeColumn = ({
  rowId,
  columnId,
}: {
  rowId: string
  columnId: string
}) => {
  const { deviceId } = useParams()
  const queryKey = ['dashboards', 'device', deviceId, 'default']
  const queryClient = useQueryClient()
  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while resizing a column',
    })
  }

  const onMutate = async (variables: TSetColumnSizeFields) => {
    await queryClient.cancelQueries()
    queryClient.setQueryData<TDashboard>(queryKey, old => {
      if (!old) return

      const currentRow = old.rows.find(row => row.id === rowId)

      return {
        ...old,
        rows: old.rows.map(row =>
          row.id === currentRow?.id
            ? {
                ...row,
                columns: row.columns.map(column =>
                  column.id === columnId
                    ? { ...column, columnSize: variables.size }
                    : column,
                ),
              }
            : row,
        ),
      }
    })
  }
  const mutation = useMutation({
    mutationFn: setColumnSize({ rowId, columnId }),
    onMutate,
    onError,
  })

  return mutation
}
