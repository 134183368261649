import styled from '@emotion/styled'
import { keyframes, Tooltip } from '@mantine/core'
import { useQuery } from '@tanstack/react-query'
import { useGetLastPacket } from 'api/packets/getLoraPacketList'
import { useDayjs } from 'hooks/useDayjs'
import { useTranslation } from 'react-i18next'
import { Dayjs } from 'dayjs'
import { ComponentProps } from 'react'
import { useGetLastRawPacket } from 'api/packets/getRawPacketList'

const pulse = keyframes`
  0% {
    transform: scale(1.1);
    opacity: 0.9;
  }
  10% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  20% {
    transform: scale(1.3);
    opacity: 0.7;
  }
  30% {
    transform: scale(1.4);
    opacity: 0.6;
  }
  40% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.6);
    opacity: 0.4;
  }
  60% {
    transform: scale(1.7);
    opacity: 0.3;
  }
  70% {
    transform: scale(1.8);
    opacity: 0.2;
  }
  80% {
    transform: scale(1.9);
    opacity: 0.1;
  }
  90% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
`

const StatusWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-right: 1rem;
`

const Marker = styled.div<OnlineStatusProps>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  background: ${props =>
    props.isOnline === undefined
      ? '#747373'
      : props.isOnline
      ? 'radial-gradient(circle,rgba(41, 148, 40, 1) 30%, rgba(61, 196, 60, 1) 100%)'
      : 'radial-gradient(circle,rgba(203, 33, 33, 1) 50%,rgba(172, 43, 43, 1) 100%)'};
`

const Ping = styled.div<{ size: string }>`
  width: ${props => props.size};
  height: ${props => props.size};
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: ${pulse} 2s ease-out;
  -moz-animation: ${pulse} 2s ease-out;
  animation: ${pulse} 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background: rgb(41, 148, 40);
  background: radial-gradient(
    circle,
    rgba(41, 148, 40, 1) 30%,
    rgba(61, 196, 60, 1) 100%
  );
`

type OnlineStatusProps = {
  isOnline?: boolean
  size?: string
  tooltip?: string
}

export const OnlineStatus = ({
  isOnline,
  size = '1rem',
  tooltip,
}: OnlineStatusProps) => {
  return (
    <Tooltip
      label={
        isOnline === undefined
          ? 'Unknown'
          : isOnline
          ? tooltip ?? 'Online'
          : tooltip ?? 'Offline'
      }
    >
      <StatusWrapper>
        <Marker size={size} isOnline={isOnline} />
        {isOnline && <Ping size={size} />}
      </StatusWrapper>
    </Tooltip>
  )
}

export const DeviceOnlineStatus = ({
  size,
  withPacketInfo = true,
  lastPacket,
}: {
  size?: string
  withPacketInfo?: boolean
  lastPacket: Dayjs | null | undefined
}) => {
  const { t } = useTranslation()
  const dayjs = useDayjs()
  const { data: lastPacketDiff } = useQuery({
    queryKey: ['lastPacketDiff', lastPacket],
    queryFn: () =>
      lastPacket !== null && lastPacket !== undefined
        ? dayjs().diff(lastPacket, 'minutes')
        : null,
    refetchInterval: 60 * 1000,
  })

  const hasRecentPacket =
    typeof lastPacketDiff === 'number' && lastPacketDiff < 30

  return (
    <OnlineStatus
      size={size}
      isOnline={hasRecentPacket}
      tooltip={
        withPacketInfo && typeof lastPacketDiff === 'number' && lastPacket
          ? `${t('sensor.lastPacketReceived')} ${lastPacket.fromNow()}`
          : undefined
      }
    />
  )
}

export const LoraDeviceOnlineStatus = ({
  deviceId,
  ...props
}: { deviceId: string } & Omit<
  ComponentProps<typeof DeviceOnlineStatus>,
  'lastPacket' | 'id'
>) => {
  const { data } = useGetLastPacket({ deviceId, results: 1 })

  return <DeviceOnlineStatus lastPacket={data} {...props} />
}
export const LoraGatewayOnlineStatus = ({
  gatewayId,
  ...props
}: { gatewayId: string } & Omit<
  ComponentProps<typeof DeviceOnlineStatus>,
  'lastPacket' | 'id'
>) => {
  const { data } = useGetLastRawPacket({ deviceId: gatewayId, results: 1 })

  return <DeviceOnlineStatus lastPacket={data} {...props} />
}
