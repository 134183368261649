import { accountUrls } from './accounts'
import { alertRuleUrls } from './alertRules'
import { authUrls } from './auth'
import { deviceGroupsUrls } from './deviceGroups'
import { loraDevicesUrls } from './loraDevices'
import { gatewayUrls } from './gateways'
import { locationUrls } from './locations'
import { measurementsUrls } from './measurements'
import { organizationUrls } from './organization'
import { devicesUrls } from './devices'
import { emailsUrls } from './emails'
import { alertUrls } from './alerts'
import { snmpDevicesUrls } from './snmp'
import { parserUrls } from './parsers'
import { modelUrls } from './models'
import { packetUrls } from './packets'
import { snmpOidsUrls } from './snmpOids'
import { qrCodesUrls } from 'config/routes/qrCodes'

export const r = {
  index: '/',
  panel: '/panel',
  ...authUrls,
  ...gatewayUrls,
  ...loraDevicesUrls,
  ...locationUrls,
  ...organizationUrls,
  ...deviceGroupsUrls,
  ...accountUrls,
  ...alertRuleUrls,
  ...measurementsUrls,
  ...devicesUrls,
  ...emailsUrls,
  ...alertUrls,
  ...snmpDevicesUrls,
  ...parserUrls,
  ...modelUrls,
  ...packetUrls,
  ...snmpOidsUrls,
  ...qrCodesUrls,
}
