import { Pagination } from '../helper'
import { z } from 'zod'

export const gatewayListItemSchema = z.object({
  gatewayId: z.string(),
  eui: z.string(),
  deviceToken: z.string().nullable(),
})

export type TGatewayListItem = z.infer<typeof gatewayListItemSchema>

export type TGatewayList = Pagination<TGatewayListItem>
