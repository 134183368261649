import { showNotification } from '@mantine/notifications'
import { useQueryClient } from '@tanstack/react-query'
import {
  addColumn,
  addColumnSchema,
  TAddColumnFields,
  TAddColumnResponse,
} from 'api/dashboards/addColumn'
import {
  TDashboard,
  TDashboardColumn,
  TDashboardItemType,
} from 'api/dashboards/getDashboard'
import { AxiosError } from 'axios'
import { useFormMutation } from 'hooks/useFormMutation'
import { useParams } from 'react-router-dom'
import { ErrorResponse } from 'shared/types'

export const useInsertColumn = ({
  rowId,
  handleSuccess,
}: {
  rowId: string
  handleSuccess: () => void
}) => {
  const { deviceId } = useParams()
  const queryKey = ['dashboards', 'device', deviceId, 'default']
  const queryClient = useQueryClient()
  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while adding new column',
    })
  }

  const onSuccess = async (
    data: TAddColumnResponse,
    variables: TAddColumnFields,
  ) => {
    await queryClient.cancelQueries()
    queryClient.setQueryData<TDashboard>(queryKey, currentDashboard => {
      if (!currentDashboard) return

      return {
        ...currentDashboard,
        rows: currentDashboard.rows.map(row =>
          row.id === rowId
            ? {
                ...row,
                columns: [
                  ...row.columns,
                  {
                    id: data.columnId,
                    columnSize: variables.columnSize,
                    dashboardRowId: row.id,
                    indicator: variables.indicator,
                    orderNo: row.columns.length + 1,
                    type: 'COL' as TDashboardItemType,
                  },
                ] as TDashboardColumn[],
              }
            : row,
        ),
      }
    })
    handleSuccess()
  }
  const mutation = useFormMutation({
    mutationFn: addColumn(rowId),
    schema: addColumnSchema,
    onSuccess,
    onError,
  })

  return mutation
}
