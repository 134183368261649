import { z } from 'zod'
import { api } from '..'

const documentSchema = z.object({
  id: z.string(),
  url: z.string(),
})

export type TDocumentRespose = z.infer<typeof documentSchema>

export const uploadDocument = async (data: FormData) =>
  await api
    .post('/v1/documents', data)
    .then(({ data }) => documentSchema.parse(data))
