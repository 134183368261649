import { useGetChartMeasurements } from 'api/measurements'
import Chart from 'react-apexcharts'
import { useAreaChartOptions } from './useAreaChartOptions'
import { Group, Skeleton, Title } from '@mantine/core'
import { TIndicator } from 'shared/types'
import { LabelPopover } from '../labels/LabelPopover'
import { useDisclosureState } from 'hooks/useDisclosureState'
import { useGetChartLabels } from 'api/chartLabels'
import { useTranslation } from 'react-i18next'
import { LastMeasurement } from './LastMeasurement'
import { memo } from 'react'
import { useDashboardStore } from 'components/dashboards/hooks/useDashboardStore'
import { useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'

export type MeasurementAreaChartProps = {
  indicator: TIndicator['code']
  deviceId: string
  disabled?: boolean
}

export type TLabelPopoverState = {
  chart: ApexChart
  xasix: { min: number; max: number }
  deviceId: string
  indicatorCode: string
}

export const MeasurementAreaChart = memo(
  ({ deviceId, indicator, disabled }: MeasurementAreaChartProps) => {
    const [params, setParams] = useSearchParams()
    const queryParams = Object.fromEntries(params.entries())

    const isEditMode = useDashboardStore(state => state.isEditMode)
    const { t } = useTranslation()
    const { isOpen, close, open, state } =
      useDisclosureState<TLabelPopoverState>(false)
    const measurements = useGetChartMeasurements(deviceId, indicator)
    const labels = useGetChartLabels({
      deviceId: deviceId!,
      indicatorCode: indicator,
    })

    const { series, options } = useAreaChartOptions({
      disableToolbar: disabled,
      deviceId,
      title: indicator,
      measurements,
      labels,
      events: {
        selection: (chart, { xaxis }) => {
          open({
            chart,
            deviceId: deviceId!,
            indicatorCode: indicator,
            xasix: xaxis as {
              min: number
              max: number
            },
          })
        },
        zoomed(_chart, { xaxis }) {
          const fromDate = dayjs(xaxis.min).toISOString()
          const toDate = dayjs(xaxis.max).toISOString()

          setParams({ ...queryParams, fromDate, toDate })
        },
      },
    })
    const { isLoading, isSuccess } = measurements

    if (isLoading) return <Skeleton animate height={300} />
    if (isSuccess)
      return (
        <LabelPopover {...{ isOpen, close, open, state }}>
          <div style={{ pointerEvents: isEditMode ? 'none' : 'auto' }}>
            <Group mb="sm" position="apart" spacing={0} align="center">
              <Title order={4}>{t(`indicator.${indicator}`)}</Title>
              {disabled ? null : (
                <LastMeasurement indicator={indicator} deviceId={deviceId!} />
              )}
            </Group>
            <Chart height={350} type="area" options={options} series={series} />
          </div>
        </LabelPopover>
      )
    return null
  },
)
MeasurementAreaChart.displayName = 'MeasurementAreaChart'
