import { z } from 'zod'
import { DeviceTypeEnum } from '../devices'

export const RegisterLoraDeviceSchema = z.object({
  deviceId: z.string(),
  devEUI: z.string(),
  appEui: z.string(),
  appKey: z.string(),
  typeCode: DeviceTypeEnum,
})

export type TRegisterLoraDeviceFields = z.infer<typeof RegisterLoraDeviceSchema>

export const registerLoraDeviceResponseSchema = z.object({
  gatewayId: z.string(),
  deviceId: z.string(),
})

export type TRegisterLoraDeviceResponse = z.infer<
  typeof registerLoraDeviceResponseSchema
>
