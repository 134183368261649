import { NavigationItem } from 'components/layout'
import { TWorkspaceType } from 'components/layout/WorkspacePicker'
import { TFunction } from 'i18next'
import { BiObjectsVerticalCenter } from 'react-icons/bi'
import { BsFileRuled } from 'react-icons/bs'
import { FaNetworkWired } from 'react-icons/fa'
import { FiCpu } from 'react-icons/fi'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { MdOutlineNotificationImportant } from 'react-icons/md'
import { RiRedPacketLine, RiRouterLine } from 'react-icons/ri'
import {
  TbBinary,
  TbBuilding,
  TbHome,
  TbLayout2,
  TbList,
  TbMail,
  TbTag,
} from 'react-icons/tb'
import { r } from './routes'

export const loraNavigationConfig: (t: TFunction) => NavigationItem[] = t => [
  {
    label: t('dashboard.single'),
    type: 'link',
    icon: TbHome,
    href: r['panel'],
  },
  {
    type: 'link',
    label: t('deviceGroup.plural'),
    icon: TbLayout2,
    href: r['deviceGroup.list'],
    permissionId: 'deviceGroups',
  },
  {
    type: 'link',
    label: t('location.plural'),
    href: r['locations.list'],
    icon: HiOutlineLocationMarker,
    permissionId: 'locations',
  },
  {
    type: 'divider',
    label: t('generic.lorawan'),
    permissionId: 'lorawans',
  },

  {
    type: 'link',
    label: t('sensor.gateways'),
    icon: RiRouterLine,
    href: r['gateways.list'],
    permissionId: 'lorawans',
  },
  {
    type: 'link',
    label: t('sensor.loraDevices'),
    icon: FiCpu,
    href: r['loraDevices.list'],
    permissionId: 'lorawans',
  },
  {
    type: 'link',
    label: t('model.plural'),
    href: r['parser.list'],
    icon: TbBinary,
    permissionId: 'packets',
  },
  // {
  //   type: 'linkGroup',
  //   icon: TbList,
  //   label: t('generic.logs'),
  //   permissionId: 'lorawans',
  //   links: [
  //     {
  //       label: 'Parser tester',
  //       link: r['parser.tester'],
  //     },
  //     {
  //       label: t('packet.plural'),
  //       link: r['loraPacket.list'],
  //     },
  //     {
  //       label: t('packet.raw'),
  //       link: r['rawPacket.list']('list'),
  //     },
  //     {
  //       label: t('packet.udpLogs'),
  //       link: r['loraUdp.list'],
  //     },
  //   ],
  // },
  {
    type: 'divider',
    label: t('measurement.plural'),
    permissionId: 'measurements',
  },
  {
    type: 'link',
    label: t('measurement.plural'),
    href: r['measurements.list'],
    icon: TbList,
    permissionId: 'measurements',
  },
  {
    type: 'link',
    label: t('measurement.labels'),
    href: r['labels.list'],
    icon: TbTag,
    permissionId: 'charts',
  },
  {
    type: 'divider',
    label: t('alert.plural'),
    permissionId: 'alerts',
  },
  {
    type: 'link',
    label: t('alert.templates'),
    href: r['alertRule.list'],
    icon: BsFileRuled,
    permissionId: 'alerts',
  },
  {
    type: 'link',
    label: t('alert.plural'),
    href: r['alert.list'],
    icon: MdOutlineNotificationImportant,
    permissionId: 'alerts',
  },
]

export const snmpNavigationConfig: (t: TFunction) => NavigationItem[] = t => [
  {
    label: t('dashboard.single'),
    type: 'link',
    icon: TbHome,
    href: r['panel'],
  },
  {
    type: 'link',
    label: t('deviceGroup.plural'),
    icon: TbLayout2,
    href: r['deviceGroup.list'],
    permissionId: 'deviceGroups',
  },
  {
    type: 'link',
    label: t('location.plural'),
    href: r['locations.list'],
    icon: HiOutlineLocationMarker,
    permissionId: 'locations',
  },
  {
    type: 'divider',
    label: t('typeCode.SNMP'),
    permissionId: 'snmp',
  },
  {
    type: 'link',
    label: t('snmp.devices'),
    icon: FaNetworkWired,
    href: r['snmpDevices.list'],
    permissionId: 'snmp',
  },
  {
    type: 'link',
    label: t('snmp.oids'),
    icon: BiObjectsVerticalCenter,
    href: r['oids.list'],
    permissionId: 'snmp',
  },
  {
    type: 'link',
    label: t('packet.plural'),
    href: r['snmpPacket.list'],
    icon: RiRedPacketLine,
    permissionId: 'snmp',
  },
  {
    type: 'divider',
    label: t('alert.plural'),
    permissionId: 'alerts',
  },
  {
    type: 'link',
    label: t('alert.templates'),
    href: r['alertRule.list'],
    icon: BsFileRuled,
    permissionId: 'alerts',
  },
  {
    type: 'link',
    label: t('alert.plural'),
    href: r['alert.list'],
    icon: MdOutlineNotificationImportant,
    permissionId: 'alerts',
  },
]
export const adminNavigationConfig: (t: TFunction) => NavigationItem[] = t => [
  {
    type: 'divider',
    label: 'QR Codes',
  },
  {
    type: 'link',
    label: t('sensor.gateways'),
    icon: RiRouterLine,
    href: r['qrCodes.gateways.list'],
  },
  {
    type: 'link',
    label: t('sensor.loraDevices'),
    icon: FiCpu,
    href: r['qrCodes.loraDevices.list'],
  },
  {
    type: 'divider',
    label: 'System Data',
    permissionId: 'emails',
  },
  {
    type: 'link',
    label: t('email.plural'),
    href: r['emails.list'],
    icon: TbMail,
    permissionId: 'emails',
  },
  {
    type: 'link',
    label: t('organization.plural'),
    href: r['organizations.list'],
    icon: TbBuilding,
    permissionId: 'organizations',
  },
]

const navigationConfig = new Map<
  TWorkspaceType,
  (t: TFunction) => NavigationItem[]
>()
navigationConfig.set('lorawan', loraNavigationConfig)
navigationConfig.set('snmp', snmpNavigationConfig)
navigationConfig.set('admin', adminNavigationConfig)

export { navigationConfig }
