import { z } from 'zod'

export const availableRoles = ['Admin', 'Owner', 'Member', 'Developer'] as const
export const RoleEnum = z.enum(availableRoles)
export type TRole = z.infer<typeof RoleEnum>

export const changeRoleSchema = z.object({
  role: RoleEnum,
})

export type TChangeRoleFields = z.infer<typeof changeRoleSchema>
