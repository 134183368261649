const prefix = ''

export const locationUrls = {
  'locations.list': `${prefix}/locations`,
  'locations.view': (locationId = ':locationId') =>
    `${prefix}/locations/${locationId}`,
  'locations.edit': (locationId = ':locationId', tab = ':tabValue') =>
    `${prefix}/locations/${locationId}/edit/${tab}`,
  'locations.create': `${prefix}/locations/create`,
}
