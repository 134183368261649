import { api, getDevicesDetails } from '..'
import { z } from 'zod'
import { paginationSchema } from 'utils/paginationSchema'
import { uniqueArray } from 'utils/uniqueArray'
import loraPacket from 'lora-packet'
import { Buffer } from 'buffer'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { tryJson } from '../../utils/tryJson'
import { useDayjs } from 'hooks/useDayjs'
import { useQuery } from '@tanstack/react-query'

const hasRxpk = (data: unknown): data is { rxpk: Array<{ data: string }> } => {
  return typeof data === 'object' && data !== null && 'rxpk' in data
}

const isStat = (data: unknown): data is { stat: unknown } => {
  return typeof data === 'object' && data !== null && 'stat' in data
}

export const rawPacketListSchema = z.object({
  id: z.string(),
  deviceId: z.string().nullable(),
  tmst: z.string(),
  type: z.string(),
  data: z.string(),
  isParsed: z.boolean(),
})

export type TRawPacketList = Awaited<ReturnType<typeof getPacketList>>
export type TRawPacket = TRawPacketList['items'][number]

export type TRawPacketFilters = Partial<{
  typeCode: string
  deviceId: string
  fromDate: string
  toDate: string
  results: number
}>

export function hexToAscii(hexString: string): string {
  let asciiString = ''
  for (let i = 0; i < hexString.length; i += 2) {
    const hexByte = hexString.slice(i, i + 2)
    const asciiCode = parseInt(hexByte, 16)
    asciiString += String.fromCharCode(asciiCode)
  }
  return asciiString
}

export const getPacketList = async (filters?: TRawPacketFilters) => {
  const { items, ...data } = await api
    .get('/v1/packets/raw-packets', {
      params: filters,
    })
    .then(({ data }) => paginationSchema(rawPacketListSchema).parse(data))

  const deviceIds = uniqueArray(
    items.map(item => item.deviceId).filter(Boolean),
  ) as string[]
  const deviceDetails = await getDevicesDetails(deviceIds)

  const newItems = items.map(item => {
    const dataAscii = hexToAscii(item.data.slice(24)).split(',').join(', ')
    const dataAsciiParsed = tryJson(dataAscii)
    const dataPackets =
      typeof dataAsciiParsed === 'string'
        ? [{ mType: 'Unable to parse JSON' }]
        : hasRxpk(dataAsciiParsed)
        ? dataAsciiParsed.rxpk.map(item => {
            const packet = loraPacket.fromWire(Buffer.from(item.data, 'base64'))

            return {
              mType: packet.getMType(),
              ...Object.entries(packet).reduce<Record<string, Buffer>>(
                (acc, [key, val]) => {
                  acc[key] = val.toString('hex')

                  return acc
                },
                {},
              ),
            }
          })
        : isStat(dataAsciiParsed)
        ? [{ mType: 'Stat' }]
        : [{ mType: '' }]

    return {
      ...item,
      dataAscii,
      dataAsciiParsed: dataAsciiParsed,
      device: item.deviceId ? deviceDetails.get(item.deviceId) : null,
      dataPackets,
    }
  })
  return { ...data, items: newItems }
}

export const rawPacketListQuery = (filters?: TRawPacketFilters) => ({
  queryFn: () => getPacketList(filters),
  queryKey: ['rawPackets', filters],
  refetchOnWindowFocus: true,
  refetchInterval: 30 * 1000,
})

export const useGetRawPackets = (manualFilters?: TRawPacketFilters) =>
  usePaginatedQuery(rawPacketListQuery, manualFilters)

const FIVE_MINUTES = 5 * 60 * 1000
export const useGetLastRawPacket = (filters: TRawPacketFilters) => {
  const dayjs = useDayjs()

  return useQuery({
    queryFn: () =>
      api
        .get('/v1/packets/raw-packets', {
          params: filters,
        })
        .then(({ data }) => paginationSchema(rawPacketListSchema).parse(data)),
    queryKey: ['rawPackets', filters],
    refetchOnWindowFocus: true,
    select: data => (data.items.length > 0 ? dayjs(data.items[0].tmst) : null),
    refetchInterval: FIVE_MINUTES,
  })
}
