import { Card, Group } from '@mantine/core'
import { useFilterForm } from 'hooks/useFilterForm'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TbSearch } from 'react-icons/tb'
import { z } from 'zod'
import { AutoSubmit, InputCheckbox, InputText } from '..'

const schema = z.object({
  search: z.string().nullish(),
  organizationId: z.string().nullish(),
  isDeviceWithoutOrganization: z.boolean().catch(false),
})

export const DeviceFilters = () => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)

  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={onSubmit}>
          <Group>
            <InputText
              name="search"
              icon={TbSearch}
              placeholder={`${t('generic.search')}`}
            />
          </Group>
          <AutoSubmit />
        </form>
      </Card>
    </FormProvider>
  )
}

export const DeviceAdminFilters = () => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)

  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={onSubmit}>
          <Group>
            <InputText
              name="search"
              icon={TbSearch}
              placeholder={`${t('generic.search')}`}
            />
            <InputText name={'organizationId'} placeholder={'Organization'} />
            <InputCheckbox
              name={'isDeviceWithoutOrganization'}
              label={'Unassigned devices'}
            />
          </Group>
          <AutoSubmit />
        </form>
      </Card>
    </FormProvider>
  )
}
