import { Active, Over } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardRowSchema, TDashboard } from 'api/dashboards/getDashboard'
import { setRowOrder } from 'api/dashboards/setRowOrder'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

export const useReorderRows = ({ dashboardId }: { dashboardId: string }) => {
  const { deviceId } = useParams()
  const queryKey = ['dashboards', 'device', deviceId, 'default']
  const queryClient = useQueryClient()

  const reorderRows = useCallback(
    ({ active, over }: { active: Active; over: Over }) => {
      const activeData = dashboardRowSchema.parse(active.data.current)

      const currentDashboardData =
        queryClient.getQueryData<TDashboard>(queryKey)
      if (currentDashboardData === undefined) return

      const rowIndex = currentDashboardData.rows.findIndex(
        row => row.id === activeData.id,
      )
      if (rowIndex === undefined) return

      const newRowIndex = currentDashboardData.rows.findIndex(
        row => row.id === over.id,
      )
      if (newRowIndex === undefined) return

      queryClient.setQueryData<TDashboard>(queryKey, old => {
        if (old === undefined) return

        const currentRows = old.rows
        const newRows = arrayMove(currentRows, rowIndex, newRowIndex)

        mutation.mutate({
          rowId: active.id as string,
          orderNo: newRowIndex + 1,
        })

        return {
          ...old,
          rows: newRows,
        }
      })
    },
    [],
  )

  const mutation = useMutation({ mutationFn: setRowOrder({ dashboardId }) })

  return reorderRows
}
