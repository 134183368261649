import { z } from 'zod'

export const indicatorSchema = z.object({
  code: z.string(),
  name: z.string(),
  unit: z.string().nullish(),
})

export const indicatorsResponseSchema = z.object({
  indicators: z.array(indicatorSchema),
})

export const deviceIndicatorsResponseSchema = z.object({
  indicators: z.array(
    z.object({
      id: z.string(),
      indicator: indicatorSchema,
      indicatorCode: z.string(),
    }),
  ),
})

export type TIndicator = z.infer<typeof indicatorSchema>

export type TIndicatorsResponse = z.infer<typeof indicatorsResponseSchema>

export type TDeviceIndicatorsResponse = z.infer<
  typeof deviceIndicatorsResponseSchema
>
