import { z } from 'zod'

export const editDeviceGroupSchema = z.object({
  name: z.string(),
  description: z.string().max(100).nullish(),
  locationId: z.string(),
})
export type TEditDeviceGroupFields = z.infer<typeof editDeviceGroupSchema>

export const editDeviceGroupLocationSchema = z.object({
  locationId: z.string(),
})

export type TEditDeviceGroupLocationFields = z.infer<
  typeof editDeviceGroupLocationSchema
>
