import { useQuery } from '@tanstack/react-query'
import { api } from '..'
import { z } from 'zod'
import { paginationSchema } from 'utils/paginationSchema'

export const downlinkPresetListItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  port: z.number(),
  payload: z.string(),
  title: z.string(),
  insertMode: z.number(),
})

export const messagingPresetsQueryKey = (deviceId: string) => [
  'downlink',
  deviceId,
  'presets',
]

export type TDownlinkPresets = Awaited<ReturnType<typeof getDownlinkPresets>>
export type TDownlinkPresetListItem = TDownlinkPresets[number]
const getDownlinkPresets = (deviceId: string) =>
  api
    .get(`/v1/lorawan/devices/${deviceId}/messages/templates`, {
      params: { results: 100 },
    })
    .then(
      ({ data }) =>
        paginationSchema(downlinkPresetListItemSchema).parse(data).items,
    )

export const useGetPresets = (deviceId: string) =>
  useQuery({
    queryKey: messagingPresetsQueryKey(deviceId),
    queryFn: () => getDownlinkPresets(deviceId),
  })
