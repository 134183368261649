import { ColorScheme, MantineThemeOverride } from '@mantine/core'
import { useEffect, useMemo, useState } from 'react'
import { generateColorPalette } from 'shared/utils'
import { FontSize, useCustomizerStore } from 'store/customizerStore'
import { getSystemColorScheme } from 'utils/getSystemColorScheme'

export const theme = (
  colorScheme: ColorScheme,
  primaryColor: string,
  fontSize: FontSize,
): MantineThemeOverride => ({
  colorScheme,
  colors: {
    brand: generateColorPalette(primaryColor),
  },
  primaryColor: 'brand',
  fontFamily: 'Poppins, sans-serif',
  headings: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    Button: {
      defaultProps: {
        size: 'sm',
        radius: 'md',
      },
    },
    Card: {
      defaultProps: {
        shadow: 'lg',
        bg: colorScheme === 'light' ? '#fff' : '#25262b',
        sx: { overflow: 'visible' },
      },
    },
    Tooltip: {
      defaultProps: {
        zIndex: 1002,
      },
    },
    Popover: {
      defaultProps: {
        zIndex: 1002,
      },
    },
    Drawer: {
      defaultProps: {
        zIndex: 1002,
      },
    },
  },
  white: '#f8f9fa',
  black: '#333',
  defaultGradient: {
    deg: 45,
    from: 'brand.5',
    to: 'brand.6',
  },
  globalStyles: theme => ({
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    html: {
      ...theme.fn.fontStyles(),
      fontSize,
    },
    body: {
      minHeight: '100dvh',
      margin: 0,
    },
    '#root': {
      minHeight: '100dvh',
      display: 'flex',
      flexDirection: 'column',
    },
    '#root > *': {
      flexGrow: 1,
    },
    '.map-tiles': {
      filter:
        colorScheme === 'dark'
          ? 'brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0.3) brightness(0.7)'
          : undefined,
    },
  }),
})

export const useCreateTheme = () => {
  const [systemColor, setSystemColor] =
    useState<ColorScheme>(getSystemColorScheme)
  const presetColor = useCustomizerStore(state => state.colorScheme)
  const primaryColor = useCustomizerStore(state => state.primaryColor)
  const fontSize = useCustomizerStore(state => state.fontSize)

  useEffect(() => {
    if (!window.matchMedia) return

    const onSystemThemeChange = (e: MediaQueryListEvent) => {
      setSystemColor(e.matches ? 'dark' : 'light')
    }

    const scheme = window.matchMedia('(prefers-color-scheme: dark)')

    scheme.addEventListener('change', onSystemThemeChange)

    return () => {
      scheme.removeEventListener('change', onSystemThemeChange)
    }
  }, [])

  const colorScheme = presetColor === 'system' ? systemColor : presetColor

  return useMemo(
    () => theme(colorScheme, primaryColor, fontSize),
    [colorScheme, primaryColor, fontSize],
  )
}
