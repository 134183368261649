import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Pagination } from 'shared/types'
import { z } from 'zod'
import { api } from '..'

export const downlinkMessageItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  port: z.number(),
  payload: z.string(),
  isSent: z.boolean(),
  sentAt: z.string().nullable(),
  isConfirmed: z.boolean(),
  type: z.string().catch('PUSH'),
})

export const downlinkMessagesList = z.object({
  currentPage: z.number(),
  resultsPerPage: z.number(),
  totalPages: z.number(),
  totalResults: z.number(),
  hasPreviousPage: z.boolean(),
  empty: z.boolean(),
  items: z.array(downlinkMessageItemSchema),
})

export type TDownlinkMessageList = z.infer<typeof downlinkMessagesList>
export type TDownlinkMessage = z.infer<typeof downlinkMessageItemSchema>

export const getDownlinkMessages = async (deviceId: string) => {
  const data = await api
    .get(`/v1/lorawan/devices/${deviceId}/messages/downlink`)
    .then(({ data }) => downlinkMessagesList.parse(data))

  return data as Pagination<TDownlinkMessage>
}

export const useGetDownlinkMessages = () => {
  const { deviceId } = useParams()

  if (!deviceId) throw new Error('Missing deviceId')

  return useQuery({
    queryKey: ['downlink', deviceId, 'list'],
    queryFn: () => getDownlinkMessages(deviceId),
  })
}
