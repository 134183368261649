import { Select, SelectProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { IconType } from 'react-icons'

export interface InputSelectProps extends Omit<SelectProps, 'icon'> {
  name: string
  icon?: IconType
}

export const InputSelect = ({
  name,
  label,
  data,
  icon: Icon,
  required,
  disabled,
  ...props
}: InputSelectProps) => {
  const { control } = useFormContext()
  if (data) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { ref, ...field }, fieldState }) => {
          const { error } = fieldState
          return (
            <Select
              ref={ref}
              label={label}
              icon={Icon ? <Icon color="currentColor" size={18} /> : null}
              data={data}
              error={error?.message}
              disabled={disabled}
              required={required}
              searchable
              nothingFound="Brak wyników"
              {...field}
              {...props}
            />
          )
        }}
      />
    )
  }
  return null
}
