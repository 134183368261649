import { Active, Over } from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dashboardColumnSchema, TDashboard } from 'api/dashboards/getDashboard'
import { setColumnOrder } from 'api/dashboards/setColumnOrder'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'

export const useReorderColumns = () => {
  const { deviceId } = useParams()
  const queryKey = ['dashboards', 'device', deviceId, 'default']
  const queryClient = useQueryClient()

  const reorderColumns = useCallback(
    ({ active, over }: { active: Active; over: Over }) => {
      const activeData = dashboardColumnSchema.parse(active.data.current)

      const currentDashboardData =
        queryClient.getQueryData<TDashboard>(queryKey)
      if (currentDashboardData === undefined) return

      const rowIndex = currentDashboardData.rows.findIndex(
        row => row.id === activeData.dashboardRowId,
      )
      if (rowIndex === undefined) return

      const columnIndex = currentDashboardData.rows[rowIndex].columns.findIndex(
        column => column.id === active.id,
      )
      if (columnIndex === undefined) return

      const newColumnIndex = currentDashboardData.rows[
        rowIndex
      ].columns.findIndex(column => column.id === over.id)
      if (newColumnIndex === undefined) return

      queryClient.setQueryData<TDashboard>(queryKey, old => {
        if (old === undefined) return

        const currentColumns = old.rows[rowIndex].columns
        const newColumns = arrayMove(
          currentColumns,
          columnIndex,
          newColumnIndex,
        )

        mutation.mutate({
          rowId: currentDashboardData.rows[rowIndex].id,
          columnId: active.id as string,
          orderNo: newColumnIndex,
        })

        return {
          ...old,
          rows: old.rows.map(row =>
            row.id === old.rows[rowIndex].id
              ? { ...row, columns: newColumns }
              : row,
          ),
        }
      })
    },
    [],
  )

  const mutation = useMutation({ mutationFn: setColumnOrder })

  return reorderColumns
}
