import { Card } from '@mantine/core'
import {
  TDashboard,
  useGetDeviceDefaultDashboard,
} from 'api/dashboards/getDashboard'
import withSignalR from 'components/common/SignalRProvider'
import { DashboardProvider } from 'components/dashboards/DashboardProvider'
import { createContext, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { TDeviceType } from 'views/panel/Devices/RegisterDevice/api/registerDevice'
import { Query } from '..'

export const DeviceIndicators = withSignalR(
  ({
    deviceModelId,
    deviceType,
  }: {
    deviceModelId: string | null
    deviceType: TDeviceType
  }) => {
    const { deviceId } = useParams()
    const query = useGetDeviceDefaultDashboard({
      deviceId: deviceId!,
      deviceModelId: deviceModelId ?? undefined,
    })

    return (
      <DeviceModelContext.Provider value={deviceModelId}>
        <DeviceTypeContext.Provider value={deviceType}>
          <Card>
            <Query.Wrapper query={query}>
              <Query.Data>
                {(data: TDashboard) => (
                  <DashboardProvider onChange={query.refetch} data={data} />
                )}
              </Query.Data>
            </Query.Wrapper>
          </Card>
        </DeviceTypeContext.Provider>
      </DeviceModelContext.Provider>
    )
  },
)

const DeviceModelContext = createContext<string | null>(null)
export const useDeviceModelContext = () => useContext(DeviceModelContext)

const DeviceTypeContext = createContext<TDeviceType | null>(null)
export const useDeviceTypeContext = () => {
  const deviceType = useContext(DeviceTypeContext)
  if (deviceType === null) throw new Error('Missing device type info')

  return deviceType
}
