import { Box } from '@mantine/core'
import { deviceListHelper } from 'api/devices'
import { InputAsyncSearchSelect } from 'components/form/input/Input_AsyncSearchSelect'
import { useTranslation } from 'react-i18next'
import { capitalizeFirstLetter } from 'shared/utils'
import { SelectItemWithIcon } from '..'

export const InputDeviceSelect = ({
  name = 'deviceId',
  params = {},
  label,
}: {
  name?: string
  label?: string
  params?: Record<string, string>
}) => {
  const { t } = useTranslation()

  const placeholder = label ?? capitalizeFirstLetter(t('sensor.device'))

  return (
    <Box w={250}>
      <InputAsyncSearchSelect
        width={'100%'}
        name={name}
        placeholder={placeholder}
        clearable
        itemComponent={SelectItemWithIcon}
        {...deviceListHelper(params)}
      />
    </Box>
  )
}
