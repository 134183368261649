import {
  Button,
  Card,
  CardProps,
  Group,
  Stack,
  Text,
  Title,
  useMantineTheme,
} from '@mantine/core'
import { MouseEventHandler, ReactNode } from 'react'
import { IconType } from 'react-icons'
import { TbPlus } from 'react-icons/tb'
import { Link, To, useSearchParams } from 'react-router-dom'

export type NoDataPromptProps = {
  buttonContent?: string | ReactNode
  to?: To
  onClick?: MouseEventHandler<HTMLButtonElement>
  icon?: IconType
  title: string | ReactNode
  titleNoFiltered?: string | ReactNode
  description?: string | ReactNode
  isLoading?: boolean
} & Partial<CardProps>

export const NoDataPrompt = ({
  icon: Icon,
  title,
  description,
  to,
  buttonContent,
  onClick,
  titleNoFiltered,
  isLoading = false,
  ...props
}: NoDataPromptProps) => {
  const theme = useMantineTheme()
  const [searchParams] = useSearchParams()
  const hasFilters =
    Object.keys(Object.fromEntries(searchParams.entries())).filter(
      key => key !== 'page' && key !== 'results',
    ).length > 0

  return (
    <Card data-testid="nodata" sx={{ boxShadow: 'none' }} p={50} {...props}>
      <Stack spacing="lg" align="center" justify="center">
        {Icon && <Icon size={'3rem'} color={theme.colors.brand[5]} />}
        <Stack
          sx={{ textAlign: 'center' }}
          spacing={0}
          align="center"
          justify="center"
        >
          {(!hasFilters || (hasFilters && !titleNoFiltered)) && (
            <Title order={3}>{title}</Title>
          )}
          {hasFilters && !!titleNoFiltered && (
            <Title order={3}>{titleNoFiltered}</Title>
          )}
          {description && (
            <Text sx={{ textAlign: 'center' }} color="dimmed">
              {description}
            </Text>
          )}
        </Stack>
        {to && (
          <Button loading={isLoading} size="sm" component={Link} to={to}>
            <Group spacing={'md'} align="center">
              <TbPlus />
              {buttonContent}
            </Group>
          </Button>
        )}
        {onClick && (
          <Button loading={isLoading} size="sm" onClick={onClick}>
            <Group spacing={'md'} align="center">
              <TbPlus />
              {buttonContent}
            </Group>
          </Button>
        )}
      </Stack>
    </Card>
  )
}
